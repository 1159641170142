import React, { useEffect, useState, useRef } from "react";
import callApi from "../services/apiService";
import NotFound from "./NotFound";

interface Item {
  mcid: string;
  title: string;
  updated: Date;
}

const ListAllItems: React.FC<{ product: string }> = ({ product }) => {
  const [items, setItems] = useState<Item[]>();
  const [noItemsForProduct, setNoItemsForProduct] = useState(false);

  const [loading, setLoading] = useState(true);
  const effectRan = useRef(false);

  useEffect(() => {
    if (effectRan.current) {
      return;
    }
    effectRan.current = true;

    const fetchSiteList = async () => {
      try {
        if (product === "all") {
          const items: Item[] = await callApi.getAllMessageCenterItems();
          setItems(items);
        } else {
          const items: Item[] = await callApi.getMessageCenterItemsByProduct(
            product
          );
          setItems(items);
          if (items.length === 0) {
            //check to see if it's a real product
            const validProducts = await callApi.getAllProducts();

            if (
              validProducts.some(
                (item: string) =>
                  item
                    .toLowerCase()
                    .replaceAll("&", "and")
                    .replace(/ /g, "-") ===
                  decodeURIComponent(product.toLowerCase()).replace(/ /g, "-")
              )
            ) {
              setNoItemsForProduct(true);
            } else {
              setNoItemsForProduct(false);
            }
          }
        }
        setLoading(false);
      } catch (error) {
        console.error("Failed to fetch site list:", error);
      }
    };

    fetchSiteList();
  }, [product]);

  return (
    <>
      {!loading &&
        items?.map((item) => {
          const title = item.mcid + ": " + item.title;
          return (
            <li key={item.mcid}>
              <a href={`${window.location.origin}/${item.mcid}`}>{title}</a>
            </li>
          );
        })}

      {loading && <p>Loading...</p>}

      {!loading && !noItemsForProduct && items?.length === 0 && <NotFound />}

      {!loading && noItemsForProduct && (
        <>
          <h3>
            There aren't any Message Center items for this product at this time.
          </h3>
        </>
      )}
    </>
  );
};

export default ListAllItems;
