import React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Link from "@mui/material/Link";
import logo from "../images/ChangePilotWhite300px.png";
import Container from "@mui/material/Container";

const TopMenuBar: React.FC = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" sx={{ backgroundColor: "#2E3838" }}>
        <Toolbar>
          <Container maxWidth="lg">
            <Link href="/" color="inherit" underline="none">
              <img src={logo} alt="ChangePilot" width={"150px"} />
            </Link>
          </Container>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default TopMenuBar;
