import React from "react";
import TopMenuBar from "./TopMenuBar";
import ListAllItems from "./ListAllItems";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import { Helmet } from "react-helmet";

function getUrl(): string {
  if (typeof window == "undefined") return "";
  return window.location.href;
}

const AllItemsPage: React.FC = () => {
  let metaTitle = `All Items - ChangePilot `;
  let metaDescription = `All Microsoft 365 Message Center items`;
  const fallBackLogo =
    window.location.protocol +
    "//" +
    window.location.hostname +
    "/ChangePilotFaviconMint500px.png";
  const jsonLd = {
    "@context": "https://schema.org",
    "@type": "WebSite",
    name: metaTitle,
    alternateName: "ChangePilot from Empowering Cloud",
    url: getUrl(),
    image: fallBackLogo,
  };

  return (
    <>
      <TopMenuBar />
      <Helmet>
        <title>{metaTitle}</title>
        {metaDescription && (
          <meta name="description" content={metaDescription} />
        )}
        {metaTitle && <meta property="og:title" content={metaTitle} />}
        {metaDescription && (
          <meta property="og:description" content={metaDescription} />
        )}
        <meta property="og:type" content="website"></meta>
        <script type="application/ld+json">{JSON.stringify(jsonLd)}</script>
        {getUrl() && <meta property="og:url" content={getUrl()} />}
        <meta property="og:image" content={fallBackLogo} />
        <link rel="canonical" href={getUrl()} />
      </Helmet>
      <div>
        <Container>
          <Box component="section" sx={{ p: 2 }}>
            <h1>All Items</h1>
            <ListAllItems product="all" />
          </Box>
        </Container>
      </div>
    </>
  );
};

export default AllItemsPage;
