import React, { useEffect, useState, useRef } from "react";
import callApi from "../../services/apiService";
import TopMenuBar from "../TopMenuBar";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid2";
import { Button } from "@mui/material";
import { AgGridReact } from "ag-grid-react"; // React Data Grid Component

import { AllCommunityModule, ModuleRegistry } from "ag-grid-community";
import { themeBalham } from "ag-grid-community";
import { GridOptions, ICellRendererParams } from "ag-grid-community";
import { Helmet } from "react-helmet";

ModuleRegistry.registerModules([AllCommunityModule]);
// provideGlobalGridOptions({ theme: "legacy" });

interface Item {
  id: string;
  lastUpdated: Date;
  title: string;
  primaryService: string;
  adminImpact: string;
  userImpact: string;
  stardardStartDate: Date;
}

function getUrl(): string {
  if (typeof window == "undefined") return "";
  return window.location.href;
}

const NotLoggedInPage: React.FC = () => {
  const [items, setItems] = useState<Item[]>();
  const effectRan = useRef(false);
  const gridTheme = themeBalham.withParams({
    headerHeight: 30,
    headerTextColor: "black",
    rangeSelectionBorderColor: "rgb(60, 188, 0, 0.0)",
  });
  const rowHeight = 70;

  let metaTitle = "ChangePilot Portal";
  let metaDescription = `Microsoft 365 Message Center items - Stay on top of change with ChangePilot`;
  const fallBackLogo =
    window.location.protocol +
    "//" +
    window.location.hostname +
    "/ChangePilotFaviconMint500px.png";
  const jsonLd = {
    "@context": "https://schema.org",
    "@type": "WebSite",
    name: metaTitle,
    alternateName: "ChangePilot from Empowering Cloud",
    url: getUrl(),
    image: fallBackLogo,
  };

  const ImpactRenderer: React.FC<ICellRendererParams> = (props) => {
    if (props.value === 1) {
      return <span>Low 🟢</span>;
    } else if (props.value === 2) {
      return <span>Medium 🟠</span>;
    } else if (props.value === 3) {
      return <span>High 🔴</span>;
    } else {
      return <span>Unknown</span>;
    }
  };

  const DateRenderer: React.FC<ICellRendererParams> = (props) => {
    const date = new Date(props.value);

    // Format the date using the user's locale
    const formattedDate = date.toLocaleDateString();
    return <span>{formattedDate}</span>;
  };

  const ServiceRenderer: React.FC<ICellRendererParams> = (props) => {
    const url = "/" + props.value.replace(/ /g, "-").toLowerCase();

    // Format the date using the user's locale

    return <a href={url}>{props.value}</a>;
  };

  const MCIDRenderer: React.FC<ICellRendererParams> = (props) => {
    const url = "/" + props.value.replace(/ /g, "-").toLowerCase();

    // Format the date using the user's locale

    return <a href={url}>{props.value}</a>;
  };

  const columnDefs: GridOptions["columnDefs"] = [
    {
      headerName: "MCID",
      field: "id",
      width: 100,
      cellRenderer: MCIDRenderer,
      filter: "agTextColumnFilter",
      sortable: true,
      headerTooltip: "Unique Microsoft Message Center ID number",
    },

    {
      field: "title",
      wrapText: true,
      minWidth: 200,
      flex: 1,
      filter: "agTextColumnFilter",
      sortable: true,
    },
    {
      field: "primaryService",
      wrapText: true,
      wrapHeaderText: true,
      width: 110,
      cellRenderer: ServiceRenderer,
      headerTooltip: "The main service impacted by this change",
    },
    {
      field: "adminImpact",
      width: 100,
      cellRenderer: ImpactRenderer,
      wrapHeaderText: true,
      sortable: true,
      headerTooltip:
        "High, config/decision required, Medium, be aware/optional change, Low, no action",
    },
    {
      field: "userImpact",
      width: 100,
      cellRenderer: ImpactRenderer,
      wrapHeaderText: true,
      sortable: true,
      headerTooltip:
        "High, communicate to users, Medium, optional to communicate, Low, no communication to users needed",
    },
    {
      field: "lastUpdated",
      headerName: "Posted / Updated",
      cellRenderer: DateRenderer,
      width: 140,
      sortable: true,
      filter: "agDateColumnFilter",
      headerTooltip: "Date Message was released and/or updated by Microsoft",
    },
    {
      field: "standardStartDate",
      headerName: "Release Start",
      cellRenderer: DateRenderer,
      width: 140,
      sortable: true,
      filter: "agDateColumnFilter",
      headerTooltip:
        "Date change should start coming to first tenants, rollouts typically take 1 month+ to reach all tenants",
    },
  ];

  useEffect(() => {
    if (effectRan.current) {
      return;
    }
    effectRan.current = true;

    const fetchRecentItems = async () => {
      const items: Item[] = await callApi.getMessageCenterRecentItems();
      setItems(items);
      console.log(items);
    };

    fetchRecentItems();
  }, []);

  return (
    <>
      <TopMenuBar />
      <Container maxWidth="lg" style={{ marginTop: "8vh" }}>
        <Helmet>
          <title>{metaTitle}</title>
          {metaDescription && (
            <meta name="description" content={metaDescription} />
          )}
          {metaTitle && <meta property="og:title" content={metaTitle} />}
          {metaDescription && (
            <meta property="og:description" content={metaDescription} />
          )}
          <meta property="og:type" content="website"></meta>
          <script type="application/ld+json">{JSON.stringify(jsonLd)}</script>
          {getUrl() && <meta property="og:url" content={getUrl()} />}
          <meta property="og:image" content={fallBackLogo} />
          <link rel="canonical" href={getUrl()} />
        </Helmet>
        <Grid container spacing={3}>
          <Grid size={6}>
            <Box>
              <h1>Welcome to the ChangePilot Portal</h1>
              {/* <p>Already a ChangePilot member? Sign in here:</p>
              <Button
                variant="contained"
                onClick={() => console.log("Sign in")}
                disabled={true}
              >
                Sign In (coming soon)
              </Button> */}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                viewBox="0 0 1080 1080"
                width="1080"
                height="1080"
                preserveAspectRatio="xMidYMid meet"
                style={{
                  width: "100%",
                  height: "100%",
                  transform: "translate3d(0px, 0px, 0px)",
                  contentVisibility: "visible",
                }}
              >
                <defs>
                  <clipPath id="__lottie_element_2">
                    <rect width="1080" height="1080" x="0" y="0"></rect>
                  </clipPath>
                </defs>
                <g clip-path="url(#__lottie_element_2)">
                  <g
                    transform="matrix(1.4299999475479126,0,0,1.4299999475479126,593.3920288085938,508.25)"
                    opacity="0.4"
                    style={{ display: "block" }}
                  >
                    <g opacity="1" transform="matrix(1,0,0,1,0,0)">
                      <path
                        fill="rgb(46,55,55)"
                        fill-opacity="1"
                        d=" M-18.356000900268555,292.47601318359375 C-18.356000900268555,292.47601318359375 -368.35101318359375,89.8219985961914 -368.35101318359375,89.8219985961914 C-368.35101318359375,89.8219985961914 -99.93000030517578,-64.83000183105469 -99.93000030517578,-64.83000183105469 C-99.93000030517578,-64.83000183105469 250.06500244140625,137.82400512695312 250.06500244140625,137.82400512695312 C250.06500244140625,137.82400512695312 -18.356000900268555,292.47601318359375 -18.356000900268555,292.47601318359375z M-360.1059875488281,89.83100128173828 C-360.1059875488281,89.83100128173828 -18.347999572753906,287.7139892578125 -18.347999572753906,287.7139892578125 C-18.347999572753906,287.7139892578125 241.82000732421875,137.81500244140625 241.82000732421875,137.81500244140625 C241.82000732421875,137.81500244140625 -99.93800354003906,-60.06800079345703 -99.93800354003906,-60.06800079345703 C-99.93800354003906,-60.06800079345703 -360.1059875488281,89.83100128173828 -360.1059875488281,89.83100128173828z"
                      ></path>
                    </g>
                  </g>
                  <g
                    transform="matrix(1.4299999475479126,0,0,1.4299999475479126,101.83001708984375,223.32200622558594)"
                    opacity="1"
                    style={{ display: "block" }}
                  >
                    <g
                      opacity="0.99"
                      transform="matrix(1.649999976158142,0,0,1.649999976158142,243.5,136.5)"
                    >
                      <path
                        fill="rgb(221,84,119)"
                        fill-opacity="1"
                        d=" M11.244000434875488,6.491000175476074 C5.033999919891357,10.076000213623047 -5.0329999923706055,10.076000213623047 -11.243000030517578,6.491000175476074 C-17.452999114990234,2.9059998989105225 -17.452999114990234,-2.9059998989105225 -11.243000030517578,-6.491000175476074 C-5.0329999923706055,-10.076000213623047 5.0329999923706055,-10.076000213623047 11.243000030517578,-6.491000175476074 C17.452999114990234,-2.9059998989105225 17.45400047302246,2.9059998989105225 11.244000434875488,6.491000175476074z"
                      ></path>
                    </g>
                  </g>
                  <g
                    transform="matrix(1.4299999475479126,0,0,1.4299999475479126,-178.8072509765625,-263.9500732421875)"
                    opacity="1"
                    style={{ display: "block" }}
                  >
                    <g
                      opacity="1"
                      transform="matrix(1,0,0,1,621.4409790039062,464.0669860839844)"
                    >
                      <path
                        fill="rgb(46,55,55)"
                        fill-opacity="1"
                        d=" M144.66099548339844,-45.624000549316406 C144.66099548339844,-45.624000549316406 -100.427001953125,-187.125 -100.427001953125,-187.125 C-103.27300262451172,-188.76800537109375 -106.16500091552734,-189.02200317382812 -108.73100280761719,-188.14300537109375 C-108.73100280761719,-188.14300537109375 -108.71600341796875,-188.16900634765625 -108.71600341796875,-188.16900634765625 C-108.71600341796875,-188.16900634765625 -109.16100311279297,-188.00100708007812 -109.16100311279297,-188.00100708007812 C-109.1969985961914,-187.98599243164062 -109.23400115966797,-187.97500610351562 -109.2699966430664,-187.9600067138672 C-109.2699966430664,-187.9600067138672 -118.12699890136719,-184.57000732421875 -118.12699890136719,-184.57000732421875 C-118.12699890136719,-184.57000732421875 -110.177001953125,-180.67799377441406 -110.177001953125,-180.67799377441406 C-110.21299743652344,-180.531005859375 -110.25900268554688,-180.3939971923828 -110.29199981689453,-180.2449951171875 C-110.29199981689453,-180.2449951171875 -153.5970001220703,16.69300079345703 -153.5970001220703,16.69300079345703 C-155.59500122070312,25.78700065612793 -151.593994140625,36.40299987792969 -144.66099548339844,40.40599822998047 C-144.66099548339844,40.40599822998047 94.53199768066406,178.5050048828125 94.53199768066406,178.5050048828125 C94.53199768066406,178.5050048828125 93.67900085449219,188.63999938964844 93.67900085449219,188.63999938964844 C93.67900085449219,188.63999938964844 103.36000061035156,184.88900756835938 103.36000061035156,184.88900756835938 C106.61100006103516,183.3679962158203 109.21600341796875,179.92799377441406 110.29199981689453,175.0279998779297 C110.29199981689453,175.0279998779297 153.5970001220703,-21.90999984741211 153.5970001220703,-21.90999984741211 C155.59500122070312,-31.003999710083008 151.593994140625,-41.62099838256836 144.66099548339844,-45.624000549316406z"
                      ></path>
                    </g>
                    <g
                      opacity="1"
                      transform="matrix(1,0,0,1,627.6489868164062,393.8299865722656)"
                    >
                      <path
                        fill="rgb(0,204,153)"
                        fill-opacity="1"
                        d=" M138.4530029296875,24.61400032043457 C138.4530029296875,24.61400032043457 -106.63500213623047,-116.88800048828125 -106.63500213623047,-116.88800048828125 C-109.48100280761719,-118.53099822998047 -112.3740005493164,-118.78500366210938 -114.94000244140625,-117.90599822998047 C-114.94000244140625,-117.90599822998047 -114.92400360107422,-117.93099975585938 -114.92400360107422,-117.93099975585938 C-114.92400360107422,-117.93099975585938 -115.36900329589844,-117.76300048828125 -115.36900329589844,-117.76300048828125 C-115.40499877929688,-117.74800109863281 -115.44200134277344,-117.73699951171875 -115.47799682617188,-117.72200012207031 C-115.47799682617188,-117.72200012207031 -124.33499908447266,-114.33300018310547 -124.33499908447266,-114.33300018310547 C-124.33499908447266,-114.33300018310547 -116.38500213623047,-110.44100189208984 -116.38500213623047,-110.44100189208984 C-116.4209976196289,-110.29399871826172 -116.46700286865234,-110.15699768066406 -116.5,-110.00800323486328 C-116.5,-110.00800323486328 -146.19400024414062,24.97599983215332 -146.19400024414062,24.97599983215332 C-146.19400024414062,24.97599983215332 -26.277000427246094,118.40299987792969 -26.277000427246094,118.40299987792969 C-26.277000427246094,118.40299987792969 146.19400024414062,34.380001068115234 146.19400024414062,34.380001068115234 C144.5679931640625,30.16900062561035 141.8699951171875,26.586000442504883 138.4530029296875,24.61400032043457z"
                      ></path>
                    </g>
                    <g
                      opacity="1"
                      transform="matrix(1,0,0,1,756.9849853515625,433.0639953613281)"
                    >
                      <path
                        fill="rgb(63,115,111)"
                        fill-opacity="1"
                        d=" M-0.125,13.312999725341797 C-0.125,13.312999725341797 18.531999588012695,5.36899995803833 18.531999588012695,5.36899995803833 C18.874000549316406,-1.8559999465942383 15.883999824523926,-9.321000099182129 10.973999977111816,-13.312999725341797 C10.973999977111816,-13.312999725341797 -18.55900001525879,-0.8899999856948853 -18.55900001525879,-0.8899999856948853 C-18.55900001525879,-0.8899999856948853 -0.125,13.312999725341797 -0.125,13.312999725341797z"
                      ></path>
                    </g>
                    <g
                      opacity="1"
                      transform="matrix(1,0,0,1,609.3319702148438,466.0589904785156)"
                    >
                      <path
                        fill="rgb(242,228,205)"
                        fill-opacity="1"
                        d=" M97.27400207519531,185.68600463867188 C97.27400207519531,185.68600463867188 -147.81300354003906,44.185001373291016 -147.81300354003906,44.185001373291016 C-154.74600219726562,40.18199920654297 -158.7480010986328,29.56399917602539 -156.75,20.469999313354492 C-156.75,20.469999313354492 -113.44499969482422,-176.46800231933594 -113.44499969482422,-176.46800231933594 C-111.4469985961914,-185.56199645996094 -104.20700073242188,-189.68899536132812 -97.27400207519531,-185.68600463867188 C-97.27400207519531,-185.68600463867188 147.81300354003906,-44.185001373291016 147.81300354003906,-44.185001373291016 C154.74600219726562,-40.18199920654297 158.7480010986328,-29.565000534057617 156.75,-20.47100067138672 C156.75,-20.47100067138672 113.44499969482422,176.46800231933594 113.44499969482422,176.46800231933594 C111.4469985961914,185.56199645996094 104.20700073242188,189.68899536132812 97.27400207519531,185.68600463867188z"
                      ></path>
                    </g>
                    <g
                      opacity="1"
                      transform="matrix(1,0,0,1,636.85498046875,465.0820007324219)"
                    >
                      <path
                        fill="rgb(54,109,89)"
                        fill-opacity="1"
                        d=" M122.64399719238281,-44.06999969482422 C122.64399719238281,-44.06999969482422 -122.44400024414062,-185.57000732421875 -122.44400024414062,-185.57000732421875 C-125.8030014038086,-187.50900268554688 -129.2220001220703,-187.50599670410156 -132.093994140625,-185.98899841308594 C-129.78900146484375,-186.4929962158203 -127.2770004272461,-186.14199829101562 -124.7969970703125,-184.70899963378906 C-124.7969970703125,-184.70899963378906 120.29000091552734,-43.20899963378906 120.29000091552734,-43.20899963378906 C127.2229995727539,-39.20600128173828 131.22500610351562,-28.58799934387207 129.2270050048828,-19.493999481201172 C129.2270050048828,-19.493999481201172 85.9209976196289,177.4429931640625 85.9209976196289,177.4429931640625 C84.89099884033203,182.13099670410156 82.45600128173828,185.46600341796875 79.4000015258789,187.0800018310547 C83.53700256347656,186.17599487304688 86.99099731445312,182.4239959716797 88.27400207519531,176.58200073242188 C88.27400207519531,176.58200073242188 131.5800018310547,-20.354999542236328 131.5800018310547,-20.354999542236328 C133.5780029296875,-29.448999404907227 129.57699584960938,-40.06700134277344 122.64399719238281,-44.06999969482422z"
                      ></path>
                    </g>
                    <g
                      opacity="1"
                      transform="matrix(1,0,0,1,609.3109741210938,465.8999938964844)"
                    >
                      <path
                        fill="rgb(54,109,89)"
                        fill-opacity="1"
                        d=" M91.98200225830078,172.32899475097656 C91.98200225830078,172.32899475097656 -138.51800537109375,39.314998626708984 -138.51800537109375,39.314998626708984 C-144.86300659179688,35.652000427246094 -148.5229949951172,25.93600082397461 -146.69500732421875,17.61400032043457 C-146.69500732421875,17.61400032043457 -106.77999877929688,-163.89300537109375 -106.77999877929688,-163.89300537109375 C-104.9520034790039,-172.21400451660156 -98.3270034790039,-175.99099731445312 -91.98200225830078,-172.3280029296875 C-91.98200225830078,-172.3280029296875 138.51800537109375,-39.314998626708984 138.51800537109375,-39.314998626708984 C144.86199951171875,-35.652000427246094 148.5229949951172,-25.93600082397461 146.69500732421875,-17.61400032043457 C146.69500732421875,-17.61400032043457 106.77999877929688,163.89300537109375 106.77999877929688,163.89300537109375 C104.9520034790039,172.21499633789062 98.32599639892578,175.99200439453125 91.98200225830078,172.32899475097656z"
                      ></path>
                    </g>
                    <g
                      opacity="1"
                      transform="matrix(1,0,0,1,609.3109741210938,465.8999938964844)"
                    >
                      <path
                        fill="rgb(46,55,55)"
                        fill-opacity="1"
                        d=" M91.98200225830078,172.32899475097656 C91.98200225830078,172.32899475097656 -138.51800537109375,39.314998626708984 -138.51800537109375,39.314998626708984 C-144.86300659179688,35.652000427246094 -148.5229949951172,25.93600082397461 -146.69500732421875,17.61400032043457 C-146.69500732421875,17.61400032043457 -106.77999877929688,-163.89300537109375 -106.77999877929688,-163.89300537109375 C-104.9520034790039,-172.21400451660156 -98.3270034790039,-175.99099731445312 -91.98200225830078,-172.3280029296875 C-91.98200225830078,-172.3280029296875 138.51800537109375,-39.314998626708984 138.51800537109375,-39.314998626708984 C144.86199951171875,-35.652000427246094 148.5229949951172,-25.93600082397461 146.69500732421875,-17.61400032043457 C146.69500732421875,-17.61400032043457 106.77999877929688,163.89300537109375 106.77999877929688,163.89300537109375 C104.9520034790039,172.21499633789062 98.32599639892578,175.99200439453125 91.98200225830078,172.32899475097656z"
                      ></path>
                    </g>
                    <g
                      opacity="1"
                      transform="matrix(1,0,0,1,586.2139892578125,466.02801513671875)"
                    >
                      <path
                        fill="rgb(0,204,153)"
                        fill-opacity="1"
                        d=" M117.08799743652344,171.3820037841797 C117.08799743652344,171.3820037841797 -113.41200256347656,38.36800003051758 -113.41200256347656,38.36800003051758 C-119.75599670410156,34.70600128173828 -123.41699981689453,24.989999771118164 -121.58899688720703,16.66900062561035 C-121.58899688720703,16.66900062561035 -81.67400360107422,-164.83900451660156 -81.67400360107422,-164.83900451660156 C-80.7490005493164,-169.0500030517578 -78.58999633789062,-172.08299255371094 -75.86599731445312,-173.5919952392578 C-79.5199966430664,-172.65199279785156 -82.54000091552734,-169.2270050048828 -83.68299865722656,-164.02200317382812 C-83.68299865722656,-164.02200317382812 -123.5979995727539,17.485000610351562 -123.5979995727539,17.485000610351562 C-125.4260025024414,25.80699920654297 -121.76599884033203,35.52299880981445 -115.4209976196289,39.18600082397461 C-115.4209976196289,39.18600082397461 115.0790023803711,172.1999969482422 115.0790023803711,172.1999969482422 C118.21199798583984,174.00999450683594 121.41000366210938,173.99000549316406 124.06800079345703,172.51800537109375 C121.87899780273438,173.08299255371094 119.46399688720703,172.75399780273438 117.08799743652344,171.3820037841797z"
                      ></path>
                    </g>
                    <g
                      opacity="1"
                      transform="matrix(1,0,0,1,484.8370056152344,652.1179809570312)"
                    >
                      <path
                        fill="rgb(46,55,55)"
                        fill-opacity="1"
                        d=" M225.1529998779297,5.789000034332275 C225.1529998779297,5.789000034332275 191.6510009765625,1.3650000095367432 191.6510009765625,1.3650000095367432 C191.6510009765625,1.3650000095367432 -24.608999252319336,-123.49299621582031 -24.608999252319336,-123.49299621582031 C-31.54199981689453,-127.49600219726562 -43.65299987792969,-126.99400329589844 -51.659000396728516,-122.37200164794922 C-51.659000396728516,-122.37200164794922 -204.89999389648438,-33.93000030517578 -204.89999389648438,-33.93000030517578 C-204.89999389648438,-33.93000030517578 -225.1580047607422,-36.60499954223633 -225.1580047607422,-36.60499954223633 C-225.1580047607422,-36.60499954223633 -225.1580047607422,-25.111000061035156 -225.1580047607422,-25.111000061035156 C-225.1580047607422,-25.111000061035156 -225.13800048828125,-25.107999801635742 -225.13800048828125,-25.107999801635742 C-225.27000427246094,-22.45199966430664 -223.75799560546875,-19.89699935913086 -220.48699951171875,-18.007999420166016 C-220.48699951171875,-18.007999420166016 24.600000381469727,123.49299621582031 24.600000381469727,123.49299621582031 C31.533000946044922,127.49600219726562 43.643001556396484,126.99400329589844 51.64899826049805,122.37200164794922 C51.64899826049805,122.37200164794922 218.53500366210938,26.052000045776367 218.53500366210938,26.052000045776367 C223.03900146484375,23.45199966430664 225.28399658203125,20.101999282836914 225.1529998779297,16.95599937438965 C225.1529998779297,16.95599937438965 225.1529998779297,16.95599937438965 225.1529998779297,16.95599937438965 C225.1529998779297,16.95599937438965 225.1529998779297,5.789000034332275 225.1529998779297,5.789000034332275z"
                      ></path>
                    </g>
                    <g
                      opacity="1"
                      transform="matrix(1,0,0,1,403.5880126953125,674.2210083007812)"
                    >
                      <path
                        fill="rgb(0,204,153)"
                        fill-opacity="1"
                        d=" M143.91000366210938,-95.20899963378906 C143.91000366210938,-95.20899963378906 128.5449981689453,-104.08000183105469 128.5449981689453,-104.08000183105469 C128.5449981689453,-104.08000183105469 -40.375999450683594,-104.08000183105469 -40.375999450683594,-104.08000183105469 C-40.375999450683594,-104.08000183105469 -123.6520004272461,-56.03300094604492 -123.6520004272461,-56.03300094604492 C-123.6520004272461,-56.03300094604492 -143.91000366210938,-58.70800018310547 -143.91000366210938,-58.70800018310547 C-143.91000366210938,-58.70800018310547 -143.91000366210938,-47.2140007019043 -143.91000366210938,-47.2140007019043 C-143.91000366210938,-47.2140007019043 -143.88999938964844,-47.21099853515625 -143.88999938964844,-47.21099853515625 C-144.02200317382812,-44.55500030517578 -142.50999450683594,-42 -139.23899841308594,-40.111000061035156 C-139.23899841308594,-40.111000061035156 105.8479995727539,101.38999938964844 105.8479995727539,101.38999938964844 C109.51200103759766,103.50499725341797 114.62200164794922,104.3499984741211 119.80799865722656,104.00599670410156 C119.80799865722656,104.00599670410156 143.91000366210938,-95.20899963378906 143.91000366210938,-95.20899963378906z"
                      ></path>
                    </g>
                    <g
                      opacity="1"
                      transform="matrix(1,0,0,1,264.1730041503906,626.0590209960938)"
                    >
                      <path
                        fill="rgb(0,204,153)"
                        fill-opacity="1"
                        d=" M4.494999885559082,-9.357999801635742 C4.494999885559082,-9.357999801635742 -4.494999885559082,-10.545000076293945 -4.494999885559082,-10.545000076293945 C-4.494999885559082,-10.545000076293945 -4.494999885559082,0.9490000009536743 -4.494999885559082,0.9490000009536743 C-4.494999885559082,0.9490000009536743 -4.474999904632568,0.9520000219345093 -4.474999904632568,0.9520000219345093 C-4.60699987411499,3.6080000400543213 -3.0950000286102295,6.163000106811523 0.17599999904632568,8.052000045776367 C0.17599999904632568,8.052000045776367 4.494999885559082,10.545000076293945 4.494999885559082,10.545000076293945 C4.494999885559082,10.545000076293945 4.494999885559082,-9.357999801635742 4.494999885559082,-9.357999801635742z"
                      ></path>
                    </g>
                    <g
                      opacity="1"
                      transform="matrix(1,0,0,1,523.39599609375,764)"
                    >
                      <path
                        fill="rgb(63,115,111)"
                        fill-opacity="1"
                        d=" M-11.491999626159668,12.758000373840332 C-4.921000003814697,15.25 4.4770002365112305,14.708000183105469 11.491999626159668,11.331000328063965 C11.491999626159668,11.331000328063965 11.491999626159668,-14.307000160217285 11.491999626159668,-14.307000160217285 C11.491999626159668,-14.307000160217285 -11.491999626159668,-14.307000160217285 -11.491999626159668,-14.307000160217285 C-11.491999626159668,-14.307000160217285 -11.491999626159668,12.758000373840332 -11.491999626159668,12.758000373840332z"
                      ></path>
                    </g>
                    <g
                      opacity="1"
                      transform="matrix(1,0,0,1,484.8320007324219,636.8510131835938)"
                    >
                      <path
                        fill="rgb(242,228,205)"
                        fill-opacity="1"
                        d=" M24.604999542236328,127.27999877929688 C24.604999542236328,127.27999877929688 -220.48199462890625,-14.222000122070312 -220.48199462890625,-14.222000122070312 C-227.4149932861328,-18.225000381469727 -226.5449981689453,-25.216999053955078 -218.5399932861328,-29.839000701904297 C-218.5399932861328,-29.839000701904297 -51.65399932861328,-126.15799713134766 -51.65399932861328,-126.15799713134766 C-43.64799880981445,-130.77999877929688 -31.538000106811523,-131.28199768066406 -24.604999542236328,-127.27899932861328 C-24.604999542236328,-127.27899932861328 220.48199462890625,14.222000122070312 220.48199462890625,14.222000122070312 C227.4149932861328,18.225000381469727 226.54600524902344,25.216999053955078 218.5399932861328,29.839000701904297 C218.5399932861328,29.839000701904297 51.65399932861328,126.15799713134766 51.65399932861328,126.15799713134766 C43.64799880981445,130.77999877929688 31.538000106811523,131.2830047607422 24.604999542236328,127.27999877929688z"
                      ></path>
                    </g>
                    <g
                      opacity="1"
                      transform="matrix(1,0,0,1,484.83099365234375,690.2750244140625)"
                    >
                      <path
                        fill="rgb(54,109,89)"
                        fill-opacity="1"
                        d=" M218.5399932861328,-27.783000946044922 C218.5399932861328,-27.783000946044922 51.654998779296875,68.53600311279297 51.654998779296875,68.53600311279297 C43.64899826049805,73.15799713134766 31.538000106811523,73.66000366210938 24.604999542236328,69.65799713134766 C24.604999542236328,69.65799713134766 -220.48199462890625,-71.84300231933594 -220.48199462890625,-71.84300231933594 C-222.7550048828125,-73.15499877929688 -224.17300415039062,-74.79100036621094 -224.79200744628906,-76.55799865722656 C-225.8769989013672,-73.27799987792969 -224.49400329589844,-69.96299743652344 -220.48199462890625,-67.6449966430664 C-220.48199462890625,-67.6449966430664 24.604999542236328,73.85600280761719 24.604999542236328,73.85600280761719 C31.538000106811523,77.85900115966797 43.64899826049805,77.35700225830078 51.654998779296875,72.73500061035156 C51.654998779296875,72.73500061035156 218.5399932861328,-23.584999084472656 218.5399932861328,-23.584999084472656 C223.92100524902344,-26.691999435424805 226.06199645996094,-30.865999221801758 224.79299926757812,-34.48699951171875 C224.00399780273438,-32.097999572753906 221.91299438476562,-29.729999542236328 218.5399932861328,-27.783000946044922z"
                      ></path>
                    </g>
                    <g opacity="1" transform="matrix(1,0,0,1,0,0)">
                      <g
                        opacity="1"
                        transform="matrix(1,0,0,1,371.45001220703125,567.72998046875)"
                      >
                        <path
                          fill="rgb(0,204,153)"
                          fill-opacity="1"
                          d=" M-2.8610000610351562,8.055000305175781 C-2.8610000610351562,8.055000305175781 -13.951000213623047,1.6519999504089355 -13.951000213623047,1.6519999504089355 C-14.840999603271484,1.1380000114440918 -14.729000091552734,0.23999999463558197 -13.701000213623047,-0.3540000021457672 C-13.701000213623047,-0.3540000021457672 -0.6119999885559082,-7.910999774932861 -0.6119999885559082,-7.910999774932861 C0.41600000858306885,-8.505000114440918 1.972000002861023,-8.567999839782715 2.861999988555908,-8.053999900817871 C2.861999988555908,-8.053999900817871 13.949999809265137,-1.6519999504089355 13.949999809265137,-1.6519999504089355 C14.840999603271484,-1.1380000114440918 14.729000091552734,-0.23999999463558197 13.701000213623047,0.3540000021457672 C13.701000213623047,0.3540000021457672 0.6119999885559082,7.909999847412109 0.6119999885559082,7.909999847412109 C-0.41600000858306885,8.503999710083008 -1.9709999561309814,8.569000244140625 -2.8610000610351562,8.055000305175781z"
                        ></path>
                      </g>
                      <g
                        opacity="1"
                        transform="matrix(1,0,0,1,388.57501220703125,577.6170043945312)"
                      >
                        <path
                          fill="rgb(0,204,153)"
                          fill-opacity="1"
                          d=" M-2.8610000610351562,8.055000305175781 C-2.8610000610351562,8.055000305175781 -13.951000213623047,1.6519999504089355 -13.951000213623047,1.6519999504089355 C-14.840999603271484,1.1380000114440918 -14.729000091552734,0.23999999463558197 -13.701000213623047,-0.3540000021457672 C-13.701000213623047,-0.3540000021457672 -0.6119999885559082,-7.910999774932861 -0.6119999885559082,-7.910999774932861 C0.41600000858306885,-8.505000114440918 1.9709999561309814,-8.567999839782715 2.8610000610351562,-8.053999900817871 C2.8610000610351562,-8.053999900817871 13.951000213623047,-1.6519999504089355 13.951000213623047,-1.6519999504089355 C14.840999603271484,-1.1380000114440918 14.729000091552734,-0.23999999463558197 13.701000213623047,0.3540000021457672 C13.701000213623047,0.3540000021457672 0.6119999885559082,7.909999847412109 0.6119999885559082,7.909999847412109 C-0.41600000858306885,8.503999710083008 -1.9709999561309814,8.569000244140625 -2.8610000610351562,8.055000305175781z"
                        ></path>
                      </g>
                      <g
                        opacity="1"
                        transform="matrix(1,0,0,1,405.70001220703125,587.5040283203125)"
                      >
                        <path
                          fill="rgb(0,204,153)"
                          fill-opacity="1"
                          d=" M-2.861999988555908,8.053999900817871 C-2.861999988555908,8.053999900817871 -13.951000213623047,1.6519999504089355 -13.951000213623047,1.6519999504089355 C-14.840999603271484,1.1380000114440918 -14.729000091552734,0.23999999463558197 -13.701000213623047,-0.3540000021457672 C-13.701000213623047,-0.3540000021457672 -0.6119999885559082,-7.910999774932861 -0.6119999885559082,-7.910999774932861 C0.41600000858306885,-8.505000114440918 1.9709999561309814,-8.567999839782715 2.8610000610351562,-8.053999900817871 C2.8610000610351562,-8.053999900817871 13.951000213623047,-1.6519999504089355 13.951000213623047,-1.6519999504089355 C14.840999603271484,-1.1380000114440918 14.729000091552734,-0.2409999966621399 13.701000213623047,0.3529999852180481 C13.701000213623047,0.3529999852180481 0.6119999885559082,7.909999847412109 0.6119999885559082,7.909999847412109 C-0.41600000858306885,8.503999710083008 -1.9709999561309814,8.567999839782715 -2.861999988555908,8.053999900817871z"
                        ></path>
                      </g>
                      <g
                        opacity="1"
                        transform="matrix(1,0,0,1,428.3340148925781,579.9459838867188)"
                      >
                        <path
                          fill="rgb(0,204,153)"
                          fill-opacity="1"
                          d=" M-1.8109999895095825,7.447999954223633 C-1.8109999895095825,7.447999954223633 -12.899999618530273,1.0460000038146973 -12.899999618530273,1.0460000038146973 C-13.789999961853027,0.5320000052452087 -13.678999900817871,-0.3659999966621399 -12.651000022888184,-0.9599999785423279 C-12.651000022888184,-0.9599999785423279 -1.6629999876022339,-7.303999900817871 -1.6629999876022339,-7.303999900817871 C-0.6349999904632568,-7.8979997634887695 0.9210000038146973,-7.961999893188477 1.8109999895095825,-7.447999954223633 C1.8109999895095825,-7.447999954223633 12.899999618530273,-1.0449999570846558 12.899999618530273,-1.0449999570846558 C13.789999961853027,-0.531000018119812 13.678000450134277,0.3659999966621399 12.649999618530273,0.9599999785423279 C12.649999618530273,0.9599999785423279 1.6629999876022339,7.303999900817871 1.6629999876022339,7.303999900817871 C0.6349999904632568,7.8979997634887695 -0.9200000166893005,7.961999893188477 -1.8109999895095825,7.447999954223633z"
                        ></path>
                      </g>
                      <g
                        opacity="1"
                        transform="matrix(1,0,0,1,445.4590148925781,589.8330078125)"
                      >
                        <path
                          fill="rgb(0,204,153)"
                          fill-opacity="1"
                          d=" M-1.8109999895095825,7.447999954223633 C-1.8109999895095825,7.447999954223633 -12.899999618530273,1.0460000038146973 -12.899999618530273,1.0460000038146973 C-13.789999961853027,0.5320000052452087 -13.678000450134277,-0.3659999966621399 -12.649999618530273,-0.9599999785423279 C-12.649999618530273,-0.9599999785423279 -1.6629999876022339,-7.303999900817871 -1.6629999876022339,-7.303999900817871 C-0.6349999904632568,-7.8979997634887695 0.9210000038146973,-7.961999893188477 1.8109999895095825,-7.447999954223633 C1.8109999895095825,-7.447999954223633 12.899999618530273,-1.0449999570846558 12.899999618530273,-1.0449999570846558 C13.791000366210938,-0.531000018119812 13.678999900817871,0.3659999966621399 12.651000022888184,0.9599999785423279 C12.651000022888184,0.9599999785423279 1.6629999876022339,7.303999900817871 1.6629999876022339,7.303999900817871 C0.6349999904632568,7.8979997634887695 -0.9210000038146973,7.961999893188477 -1.8109999895095825,7.447999954223633z"
                        ></path>
                      </g>
                      <g
                        opacity="1"
                        transform="matrix(1,0,0,1,462.5840148925781,599.719970703125)"
                      >
                        <path
                          fill="rgb(0,204,153)"
                          fill-opacity="1"
                          d=" M-1.8109999895095825,7.447999954223633 C-1.8109999895095825,7.447999954223633 -12.899999618530273,1.0460000038146973 -12.899999618530273,1.0460000038146973 C-13.789999961853027,0.5320000052452087 -13.678999900817871,-0.3659999966621399 -12.651000022888184,-0.9599999785423279 C-12.651000022888184,-0.9599999785423279 -1.6629999876022339,-7.303999900817871 -1.6629999876022339,-7.303999900817871 C-0.6349999904632568,-7.8979997634887695 0.9210000038146973,-7.961999893188477 1.8109999895095825,-7.447999954223633 C1.8109999895095825,-7.447999954223633 12.899999618530273,-1.0449999570846558 12.899999618530273,-1.0449999570846558 C13.789999961853027,-0.531000018119812 13.678000450134277,0.3659999966621399 12.649999618530273,0.9599999785423279 C12.649999618530273,0.9599999785423279 1.6629999876022339,7.303999900817871 1.6629999876022339,7.303999900817871 C0.6349999904632568,7.8979997634887695 -0.9210000038146973,7.961999893188477 -1.8109999895095825,7.447999954223633z"
                        ></path>
                      </g>
                      <g
                        opacity="1"
                        transform="matrix(1,0,0,1,479.7090148925781,609.6069946289062)"
                      >
                        <path
                          fill="rgb(0,204,153)"
                          fill-opacity="1"
                          d=" M-1.8109999895095825,7.447999954223633 C-1.8109999895095825,7.447999954223633 -12.899999618530273,1.0460000038146973 -12.899999618530273,1.0460000038146973 C-13.789999961853027,0.5320000052452087 -13.678000450134277,-0.3659999966621399 -12.649999618530273,-0.9599999785423279 C-12.649999618530273,-0.9599999785423279 -1.6629999876022339,-7.303999900817871 -1.6629999876022339,-7.303999900817871 C-0.6349999904632568,-7.8979997634887695 0.9210000038146973,-7.961999893188477 1.8109999895095825,-7.447999954223633 C1.8109999895095825,-7.447999954223633 12.899999618530273,-1.0449999570846558 12.899999618530273,-1.0449999570846558 C13.789999961853027,-0.531000018119812 13.678999900817871,0.3659999966621399 12.651000022888184,0.9599999785423279 C12.651000022888184,0.9599999785423279 1.6629999876022339,7.303999900817871 1.6629999876022339,7.303999900817871 C0.6349999904632568,7.8979997634887695 -0.9210000038146973,7.961999893188477 -1.8109999895095825,7.447999954223633z"
                        ></path>
                      </g>
                      <g
                        opacity="1"
                        transform="matrix(1,0,0,1,496.8340148925781,619.4940185546875)"
                      >
                        <path
                          fill="rgb(0,204,153)"
                          fill-opacity="1"
                          d=" M-1.8109999895095825,7.447999954223633 C-1.8109999895095825,7.447999954223633 -12.899999618530273,1.0449999570846558 -12.899999618530273,1.0449999570846558 C-13.789999961853027,0.531000018119812 -13.678000450134277,-0.3659999966621399 -12.649999618530273,-0.9599999785423279 C-12.649999618530273,-0.9599999785423279 -1.6629999876022339,-7.303999900817871 -1.6629999876022339,-7.303999900817871 C-0.6349999904632568,-7.8979997634887695 0.9210000038146973,-7.961999893188477 1.8109999895095825,-7.447999954223633 C1.8109999895095825,-7.447999954223633 12.899999618530273,-1.0449999570846558 12.899999618530273,-1.0449999570846558 C13.789999961853027,-0.531000018119812 13.678999900817871,0.3659999966621399 12.651000022888184,0.9599999785423279 C12.651000022888184,0.9599999785423279 1.6629999876022339,7.303999900817871 1.6629999876022339,7.303999900817871 C0.6349999904632568,7.8979997634887695 -0.9210000038146973,7.961999893188477 -1.8109999895095825,7.447999954223633z"
                        ></path>
                      </g>
                      <g
                        opacity="1"
                        transform="matrix(1,0,0,1,513.9600219726562,629.3820190429688)"
                      >
                        <path
                          fill="rgb(0,204,153)"
                          fill-opacity="1"
                          d=" M-1.8109999895095825,7.447999954223633 C-1.8109999895095825,7.447999954223633 -12.899999618530273,1.0449999570846558 -12.899999618530273,1.0449999570846558 C-13.789999961853027,0.531000018119812 -13.678999900817871,-0.3659999966621399 -12.651000022888184,-0.9599999785423279 C-12.651000022888184,-0.9599999785423279 -1.6629999876022339,-7.303999900817871 -1.6629999876022339,-7.303999900817871 C-0.6349999904632568,-7.8979997634887695 0.9210000038146973,-7.961999893188477 1.8109999895095825,-7.447999954223633 C1.8109999895095825,-7.447999954223633 12.899999618530273,-1.0449999570846558 12.899999618530273,-1.0449999570846558 C13.789999961853027,-0.531000018119812 13.678000450134277,0.3659999966621399 12.649999618530273,0.9599999785423279 C12.649999618530273,0.9599999785423279 1.6629999876022339,7.303999900817871 1.6629999876022339,7.303999900817871 C0.6349999904632568,7.8979997634887695 -0.9200000166893005,7.961999893188477 -1.8109999895095825,7.447999954223633z"
                        ></path>
                      </g>
                      <g
                        opacity="1"
                        transform="matrix(1,0,0,1,531.0850219726562,639.2689819335938)"
                      >
                        <path
                          fill="rgb(0,204,153)"
                          fill-opacity="1"
                          d=" M-1.8109999895095825,7.447999954223633 C-1.8109999895095825,7.447999954223633 -12.899999618530273,1.0449999570846558 -12.899999618530273,1.0449999570846558 C-13.789999961853027,0.531000018119812 -13.678000450134277,-0.3659999966621399 -12.649999618530273,-0.9599999785423279 C-12.649999618530273,-0.9599999785423279 -1.6629999876022339,-7.303999900817871 -1.6629999876022339,-7.303999900817871 C-0.6349999904632568,-7.8979997634887695 0.9210000038146973,-7.961999893188477 1.8109999895095825,-7.447999954223633 C1.8109999895095825,-7.447999954223633 12.899999618530273,-1.0449999570846558 12.899999618530273,-1.0449999570846558 C13.791000366210938,-0.531000018119812 13.678999900817871,0.3659999966621399 12.651000022888184,0.9599999785423279 C12.651000022888184,0.9599999785423279 1.6629999876022339,7.303999900817871 1.6629999876022339,7.303999900817871 C0.6349999904632568,7.8979997634887695 -0.9210000038146973,7.961999893188477 -1.8109999895095825,7.447999954223633z"
                        ></path>
                      </g>
                      <g
                        opacity="1"
                        transform="matrix(1,0,0,1,548.2100219726562,649.156005859375)"
                      >
                        <path
                          fill="rgb(0,204,153)"
                          fill-opacity="1"
                          d=" M-1.8109999895095825,7.447999954223633 C-1.8109999895095825,7.447999954223633 -12.899999618530273,1.0449999570846558 -12.899999618530273,1.0449999570846558 C-13.789999961853027,0.531000018119812 -13.678999900817871,-0.3659999966621399 -12.651000022888184,-0.9599999785423279 C-12.651000022888184,-0.9599999785423279 -1.6629999876022339,-7.303999900817871 -1.6629999876022339,-7.303999900817871 C-0.6349999904632568,-7.8979997634887695 0.9210000038146973,-7.961999893188477 1.8109999895095825,-7.447999954223633 C1.8109999895095825,-7.447999954223633 12.899999618530273,-1.0449999570846558 12.899999618530273,-1.0449999570846558 C13.789999961853027,-0.531000018119812 13.678000450134277,0.3659999966621399 12.649999618530273,0.9599999785423279 C12.649999618530273,0.9599999785423279 1.6629999876022339,7.303999900817871 1.6629999876022339,7.303999900817871 C0.6349999904632568,7.8979997634887695 -0.9210000038146973,7.961999893188477 -1.8109999895095825,7.447999954223633z"
                        ></path>
                      </g>
                      <g
                        opacity="1"
                        transform="matrix(1,0,0,1,565.3350219726562,659.0430297851562)"
                      >
                        <path
                          fill="rgb(0,204,153)"
                          fill-opacity="1"
                          d=" M-1.8109999895095825,7.447999954223633 C-1.8109999895095825,7.447999954223633 -12.899999618530273,1.0449999570846558 -12.899999618530273,1.0449999570846558 C-13.789999961853027,0.531000018119812 -13.678999900817871,-0.3659999966621399 -12.651000022888184,-0.9599999785423279 C-12.651000022888184,-0.9599999785423279 -1.6629999876022339,-7.303999900817871 -1.6629999876022339,-7.303999900817871 C-0.6349999904632568,-7.8979997634887695 0.9210000038146973,-7.961999893188477 1.8109999895095825,-7.447999954223633 C1.8109999895095825,-7.447999954223633 12.899999618530273,-1.0449999570846558 12.899999618530273,-1.0449999570846558 C13.789999961853027,-0.531000018119812 13.678999900817871,0.3659999966621399 12.651000022888184,0.9599999785423279 C12.651000022888184,0.9599999785423279 1.6629999876022339,7.303999900817871 1.6629999876022339,7.303999900817871 C0.6349999904632568,7.8979997634887695 -0.9210000038146973,7.961999893188477 -1.8109999895095825,7.447999954223633z"
                        ></path>
                      </g>
                      <g
                        opacity="1"
                        transform="matrix(1,0,0,1,582.4600219726562,668.9299926757812)"
                      >
                        <path
                          fill="rgb(0,204,153)"
                          fill-opacity="1"
                          d=" M-1.8109999895095825,7.447999954223633 C-1.8109999895095825,7.447999954223633 -12.899999618530273,1.0449999570846558 -12.899999618530273,1.0449999570846558 C-13.789999961853027,0.531000018119812 -13.678000450134277,-0.3659999966621399 -12.649999618530273,-0.9599999785423279 C-12.649999618530273,-0.9599999785423279 -1.6629999876022339,-7.303999900817871 -1.6629999876022339,-7.303999900817871 C-0.6349999904632568,-7.8979997634887695 0.9210000038146973,-7.961999893188477 1.8109999895095825,-7.447999954223633 C1.8109999895095825,-7.447999954223633 12.899999618530273,-1.0460000038146973 12.899999618530273,-1.0460000038146973 C13.789999961853027,-0.5320000052452087 13.678999900817871,0.3659999966621399 12.651000022888184,0.9599999785423279 C12.651000022888184,0.9599999785423279 1.6629999876022339,7.303999900817871 1.6629999876022339,7.303999900817871 C0.6349999904632568,7.8979997634887695 -0.9210000038146973,7.961999893188477 -1.8109999895095825,7.447999954223633z"
                        ></path>
                      </g>
                      <g
                        opacity="1"
                        transform="matrix(1,0,0,1,453.5669860839844,575.2650146484375)"
                      >
                        <path
                          fill="rgb(0,204,153)"
                          fill-opacity="1"
                          d=" M-1.8109999895095825,7.447999954223633 C-1.8109999895095825,7.447999954223633 -12.899999618530273,1.0449999570846558 -12.899999618530273,1.0449999570846558 C-13.789999961853027,0.531000018119812 -13.678000450134277,-0.3659999966621399 -12.649999618530273,-0.9599999785423279 C-12.649999618530273,-0.9599999785423279 -1.6629999876022339,-7.303999900817871 -1.6629999876022339,-7.303999900817871 C-0.6349999904632568,-7.8979997634887695 0.9210000038146973,-7.961999893188477 1.8109999895095825,-7.447999954223633 C1.8109999895095825,-7.447999954223633 12.899999618530273,-1.0460000038146973 12.899999618530273,-1.0460000038146973 C13.789999961853027,-0.5320000052452087 13.678999900817871,0.3659999966621399 12.651000022888184,0.9599999785423279 C12.651000022888184,0.9599999785423279 1.6629999876022339,7.303999900817871 1.6629999876022339,7.303999900817871 C0.6349999904632568,7.8979997634887695 -0.9210000038146973,7.961999893188477 -1.8109999895095825,7.447999954223633z"
                        ></path>
                      </g>
                      <g
                        opacity="1"
                        transform="matrix(1,0,0,1,436.1860046386719,565.22998046875)"
                      >
                        <path
                          fill="rgb(0,204,153)"
                          fill-opacity="1"
                          d=" M-1.8109999895095825,7.447999954223633 C-1.8109999895095825,7.447999954223633 -12.899999618530273,1.0460000038146973 -12.899999618530273,1.0460000038146973 C-13.789999961853027,0.5320000052452087 -13.678999900817871,-0.3659999966621399 -12.651000022888184,-0.9599999785423279 C-12.651000022888184,-0.9599999785423279 -1.6629999876022339,-7.303999900817871 -1.6629999876022339,-7.303999900817871 C-0.6349999904632568,-7.8979997634887695 0.9210000038146973,-7.961999893188477 1.8109999895095825,-7.447999954223633 C1.8109999895095825,-7.447999954223633 12.899999618530273,-1.0449999570846558 12.899999618530273,-1.0449999570846558 C13.789999961853027,-0.531000018119812 13.678000450134277,0.3659999966621399 12.649999618530273,0.9599999785423279 C12.649999618530273,0.9599999785423279 1.6629999876022339,7.303999900817871 1.6629999876022339,7.303999900817871 C0.6349999904632568,7.8979997634887695 -0.9200000166893005,7.961999893188477 -1.8109999895095825,7.447999954223633z"
                        ></path>
                      </g>
                      <g
                        opacity="1"
                        transform="matrix(1,0,0,1,412.3800048828125,551.4860229492188)"
                      >
                        <path
                          fill="rgb(0,204,153)"
                          fill-opacity="1"
                          d=" M4.585999965667725,11.140999794006348 C4.585999965667725,11.140999794006348 -19.297000885009766,-2.6480000019073486 -19.297000885009766,-2.6480000019073486 C-20.187000274658203,-3.1619999408721924 -20.075000762939453,-4.059000015258789 -19.047000885009766,-4.6529998779296875 C-19.047000885009766,-4.6529998779296875 -8.0600004196167,-10.996999740600586 -8.0600004196167,-10.996999740600586 C-7.0320000648498535,-11.590999603271484 -5.4770002365112305,-11.654999732971191 -4.586999893188477,-11.140999794006348 C-4.586999893188477,-11.140999794006348 19.297000885009766,2.6480000019073486 19.297000885009766,2.6480000019073486 C20.187000274658203,3.1619999408721924 20.075000762939453,4.059000015258789 19.047000885009766,4.6529998779296875 C19.047000885009766,4.6529998779296875 8.0600004196167,10.996999740600586 8.0600004196167,10.996999740600586 C7.0320000648498535,11.590999603271484 5.47599983215332,11.654999732971191 4.585999965667725,11.140999794006348z"
                        ></path>
                      </g>
                      <g
                        opacity="1"
                        transform="matrix(1,0,0,1,426.79998779296875,540.56201171875)"
                      >
                        <path
                          fill="rgb(0,204,153)"
                          fill-opacity="1"
                          d=" M2.3359999656677246,9.842000007629395 C2.3359999656677246,9.842000007629395 -17.04599952697754,-1.3489999771118164 -17.04599952697754,-1.3489999771118164 C-17.93600082397461,-1.8630000352859497 -17.825000762939453,-2.759999990463257 -16.797000885009766,-3.3540000915527344 C-16.797000885009766,-3.3540000915527344 -5.809000015258789,-9.697999954223633 -5.809000015258789,-9.697999954223633 C-4.781000137329102,-10.291999816894531 -3.2260000705718994,-10.355999946594238 -2.3359999656677246,-9.842000007629395 C-2.3359999656677246,-9.842000007629395 17.04599952697754,1.3480000495910645 17.04599952697754,1.3480000495910645 C17.93600082397461,1.8619999885559082 17.825000762939453,2.759999990463257 16.797000885009766,3.3540000915527344 C16.797000885009766,3.3540000915527344 5.809000015258789,9.697999954223633 5.809000015258789,9.697999954223633 C4.781000137329102,10.291999816894531 3.2260000705718994,10.355999946594238 2.3359999656677246,9.842000007629395z"
                        ></path>
                      </g>
                      <g
                        opacity="1"
                        transform="matrix(1,0,0,1,470.6919860839844,585.1519775390625)"
                      >
                        <path
                          fill="rgb(0,204,153)"
                          fill-opacity="1"
                          d=" M-1.8109999895095825,7.447999954223633 C-1.8109999895095825,7.447999954223633 -12.899999618530273,1.0449999570846558 -12.899999618530273,1.0449999570846558 C-13.789999961853027,0.531000018119812 -13.678000450134277,-0.3659999966621399 -12.649999618530273,-0.9599999785423279 C-12.649999618530273,-0.9599999785423279 -1.6629999876022339,-7.303999900817871 -1.6629999876022339,-7.303999900817871 C-0.6349999904632568,-7.8979997634887695 0.9210000038146973,-7.961999893188477 1.8109999895095825,-7.447999954223633 C1.8109999895095825,-7.447999954223633 12.899999618530273,-1.0460000038146973 12.899999618530273,-1.0460000038146973 C13.789999961853027,-0.5320000052452087 13.678999900817871,0.3659999966621399 12.651000022888184,0.9599999785423279 C12.651000022888184,0.9599999785423279 1.6629999876022339,7.303999900817871 1.6629999876022339,7.303999900817871 C0.6349999904632568,7.8979997634887695 -0.9210000038146973,7.961999893188477 -1.8109999895095825,7.447999954223633z"
                        ></path>
                      </g>
                      <g
                        opacity="1"
                        transform="matrix(1,0,0,1,487.8169860839844,595.0390014648438)"
                      >
                        <path
                          fill="rgb(0,204,153)"
                          fill-opacity="1"
                          d=" M-1.8109999895095825,7.447999954223633 C-1.8109999895095825,7.447999954223633 -12.899999618530273,1.0449999570846558 -12.899999618530273,1.0449999570846558 C-13.789999961853027,0.531000018119812 -13.678999900817871,-0.3659999966621399 -12.651000022888184,-0.9599999785423279 C-12.651000022888184,-0.9599999785423279 -1.6629999876022339,-7.303999900817871 -1.6629999876022339,-7.303999900817871 C-0.6349999904632568,-7.8979997634887695 0.9210000038146973,-7.961999893188477 1.8109999895095825,-7.447999954223633 C1.8109999895095825,-7.447999954223633 12.899999618530273,-1.0460000038146973 12.899999618530273,-1.0460000038146973 C13.789999961853027,-0.5320000052452087 13.678000450134277,0.3659999966621399 12.649999618530273,0.9599999785423279 C12.649999618530273,0.9599999785423279 1.6629999876022339,7.303999900817871 1.6629999876022339,7.303999900817871 C0.6349999904632568,7.8979997634887695 -0.9200000166893005,7.961999893188477 -1.8109999895095825,7.447999954223633z"
                        ></path>
                      </g>
                      <g
                        opacity="1"
                        transform="matrix(1,0,0,1,504.9419860839844,604.926025390625)"
                      >
                        <path
                          fill="rgb(0,204,153)"
                          fill-opacity="1"
                          d=" M-1.8109999895095825,7.447999954223633 C-1.8109999895095825,7.447999954223633 -12.899999618530273,1.0449999570846558 -12.899999618530273,1.0449999570846558 C-13.789999961853027,0.531000018119812 -13.678000450134277,-0.3659999966621399 -12.649999618530273,-0.9599999785423279 C-12.649999618530273,-0.9599999785423279 -1.6629999876022339,-7.303999900817871 -1.6629999876022339,-7.303999900817871 C-0.6349999904632568,-7.8979997634887695 0.9210000038146973,-7.961999893188477 1.8109999895095825,-7.447999954223633 C1.8109999895095825,-7.447999954223633 12.899999618530273,-1.0460000038146973 12.899999618530273,-1.0460000038146973 C13.791000366210938,-0.5320000052452087 13.678999900817871,0.3659999966621399 12.651000022888184,0.9599999785423279 C12.651000022888184,0.9599999785423279 1.6629999876022339,7.303999900817871 1.6629999876022339,7.303999900817871 C0.6349999904632568,7.8979997634887695 -0.9210000038146973,7.961999893188477 -1.8109999895095825,7.447999954223633z"
                        ></path>
                      </g>
                      <g
                        opacity="1"
                        transform="matrix(1,0,0,1,522.0670166015625,614.81298828125)"
                      >
                        <path
                          fill="rgb(0,204,153)"
                          fill-opacity="1"
                          d=" M-1.8109999895095825,7.447999954223633 C-1.8109999895095825,7.447999954223633 -12.899999618530273,1.0460000038146973 -12.899999618530273,1.0460000038146973 C-13.789999961853027,0.5320000052452087 -13.678999900817871,-0.3659999966621399 -12.651000022888184,-0.9599999785423279 C-12.651000022888184,-0.9599999785423279 -1.6629999876022339,-7.303999900817871 -1.6629999876022339,-7.303999900817871 C-0.6349999904632568,-7.8979997634887695 0.9210000038146973,-7.961999893188477 1.8109999895095825,-7.447999954223633 C1.8109999895095825,-7.447999954223633 12.899999618530273,-1.0449999570846558 12.899999618530273,-1.0449999570846558 C13.789999961853027,-0.531000018119812 13.678000450134277,0.3659999966621399 12.649999618530273,0.9599999785423279 C12.649999618530273,0.9599999785423279 1.6629999876022339,7.303999900817871 1.6629999876022339,7.303999900817871 C0.6349999904632568,7.8979997634887695 -0.9210000038146973,7.961999893188477 -1.8109999895095825,7.447999954223633z"
                        ></path>
                      </g>
                      <g
                        opacity="1"
                        transform="matrix(1,0,0,1,539.1920166015625,624.7009887695312)"
                      >
                        <path
                          fill="rgb(0,204,153)"
                          fill-opacity="1"
                          d=" M-1.8109999895095825,7.447999954223633 C-1.8109999895095825,7.447999954223633 -12.899999618530273,1.0460000038146973 -12.899999618530273,1.0460000038146973 C-13.789999961853027,0.5320000052452087 -13.678000450134277,-0.3659999966621399 -12.649999618530273,-0.9599999785423279 C-12.649999618530273,-0.9599999785423279 -1.6629999876022339,-7.303999900817871 -1.6629999876022339,-7.303999900817871 C-0.6349999904632568,-7.8979997634887695 0.9210000038146973,-7.961999893188477 1.8109999895095825,-7.447999954223633 C1.8109999895095825,-7.447999954223633 12.899999618530273,-1.0449999570846558 12.899999618530273,-1.0449999570846558 C13.789999961853027,-0.531000018119812 13.678999900817871,0.3659999966621399 12.651000022888184,0.9599999785423279 C12.651000022888184,0.9599999785423279 1.6629999876022339,7.303999900817871 1.6629999876022339,7.303999900817871 C0.6349999904632568,7.8979997634887695 -0.9210000038146973,7.961999893188477 -1.8109999895095825,7.447999954223633z"
                        ></path>
                      </g>
                      <g
                        opacity="1"
                        transform="matrix(1,0,0,1,556.3170166015625,634.5880126953125)"
                      >
                        <path
                          fill="rgb(0,204,153)"
                          fill-opacity="1"
                          d=" M-1.8109999895095825,7.447999954223633 C-1.8109999895095825,7.447999954223633 -12.899999618530273,1.0460000038146973 -12.899999618530273,1.0460000038146973 C-13.789999961853027,0.5320000052452087 -13.678000450134277,-0.3659999966621399 -12.649999618530273,-0.9599999785423279 C-12.649999618530273,-0.9599999785423279 -1.6629999876022339,-7.303999900817871 -1.6629999876022339,-7.303999900817871 C-0.6349999904632568,-7.8979997634887695 0.9210000038146973,-7.961999893188477 1.8109999895095825,-7.447999954223633 C1.8109999895095825,-7.447999954223633 12.899999618530273,-1.0449999570846558 12.899999618530273,-1.0449999570846558 C13.789999961853027,-0.531000018119812 13.678999900817871,0.3659999966621399 12.651000022888184,0.9599999785423279 C12.651000022888184,0.9599999785423279 1.6629999876022339,7.303999900817871 1.6629999876022339,7.303999900817871 C0.6349999904632568,7.8979997634887695 -0.9210000038146973,7.961999893188477 -1.8109999895095825,7.447999954223633z"
                        ></path>
                      </g>
                      <g
                        opacity="1"
                        transform="matrix(1,0,0,1,573.4420166015625,644.4749755859375)"
                      >
                        <path
                          fill="rgb(0,204,153)"
                          fill-opacity="1"
                          d=" M-1.8109999895095825,7.447999954223633 C-1.8109999895095825,7.447999954223633 -12.899999618530273,1.0460000038146973 -12.899999618530273,1.0460000038146973 C-13.789999961853027,0.5320000052452087 -13.678999900817871,-0.3659999966621399 -12.651000022888184,-0.9599999785423279 C-12.651000022888184,-0.9599999785423279 -1.6629999876022339,-7.303999900817871 -1.6629999876022339,-7.303999900817871 C-0.6349999904632568,-7.8979997634887695 0.9210000038146973,-7.961999893188477 1.8109999895095825,-7.447999954223633 C1.8109999895095825,-7.447999954223633 12.899999618530273,-1.0449999570846558 12.899999618530273,-1.0449999570846558 C13.789999961853027,-0.531000018119812 13.678000450134277,0.3659999966621399 12.649999618530273,0.9599999785423279 C12.649999618530273,0.9599999785423279 1.6629999876022339,7.303999900817871 1.6629999876022339,7.303999900817871 C0.6349999904632568,7.8979997634887695 -0.9200000166893005,7.961999893188477 -1.8109999895095825,7.447999954223633z"
                        ></path>
                      </g>
                      <g
                        opacity="1"
                        transform="matrix(1,0,0,1,590.5670166015625,654.3619995117188)"
                      >
                        <path
                          fill="rgb(0,204,153)"
                          fill-opacity="1"
                          d=" M-1.8109999895095825,7.447999954223633 C-1.8109999895095825,7.447999954223633 -12.899999618530273,1.0460000038146973 -12.899999618530273,1.0460000038146973 C-13.789999961853027,0.5320000052452087 -13.678000450134277,-0.3659999966621399 -12.649999618530273,-0.9599999785423279 C-12.649999618530273,-0.9599999785423279 -1.6629999876022339,-7.303999900817871 -1.6629999876022339,-7.303999900817871 C-0.6349999904632568,-7.8979997634887695 0.9210000038146973,-7.961999893188477 1.8109999895095825,-7.447999954223633 C1.8109999895095825,-7.447999954223633 12.899999618530273,-1.0449999570846558 12.899999618530273,-1.0449999570846558 C13.791000366210938,-0.531000018119812 13.678999900817871,0.3659999966621399 12.651000022888184,0.9599999785423279 C12.651000022888184,0.9599999785423279 1.6629999876022339,7.303999900817871 1.6629999876022339,7.303999900817871 C0.6349999904632568,7.8979997634887695 -0.9210000038146973,7.961999893188477 -1.8109999895095825,7.447999954223633z"
                        ></path>
                      </g>
                      <g
                        opacity="1"
                        transform="matrix(1,0,0,1,607.6929931640625,664.2490234375)"
                      >
                        <path
                          fill="rgb(0,204,153)"
                          fill-opacity="1"
                          d=" M-1.8109999895095825,7.447999954223633 C-1.8109999895095825,7.447999954223633 -12.899999618530273,1.0460000038146973 -12.899999618530273,1.0460000038146973 C-13.789999961853027,0.5320000052452087 -13.678999900817871,-0.3659999966621399 -12.651000022888184,-0.9599999785423279 C-12.651000022888184,-0.9599999785423279 -1.6629999876022339,-7.303999900817871 -1.6629999876022339,-7.303999900817871 C-0.6349999904632568,-7.8979997634887695 0.9210000038146973,-7.961999893188477 1.8109999895095825,-7.447999954223633 C1.8109999895095825,-7.447999954223633 12.899999618530273,-1.0449999570846558 12.899999618530273,-1.0449999570846558 C13.789999961853027,-0.531000018119812 13.678000450134277,0.3659999966621399 12.649999618530273,0.9599999785423279 C12.649999618530273,0.9599999785423279 1.6629999876022339,7.303999900817871 1.6629999876022339,7.303999900817871 C0.6349999904632568,7.8979997634887695 -0.9210000038146973,7.961999893188477 -1.8109999895095825,7.447999954223633z"
                        ></path>
                      </g>
                      <g
                        opacity="1"
                        transform="matrix(1,0,0,1,631.583984375,678.0430297851562)"
                      >
                        <path
                          fill="rgb(0,204,153)"
                          fill-opacity="1"
                          d=" M4.743000030517578,11.230999946594238 C4.743000030517578,11.230999946594238 -19.452999114990234,-2.73799991607666 -19.452999114990234,-2.73799991607666 C-20.343000411987305,-3.252000093460083 -20.23200035095215,-4.150000095367432 -19.20400047302246,-4.74399995803833 C-19.20400047302246,-4.74399995803833 -8.215999603271484,-11.086999893188477 -8.215999603271484,-11.086999893188477 C-7.188000202178955,-11.680999755859375 -5.631999969482422,-11.744999885559082 -4.742000102996826,-11.230999946594238 C-4.742000102996826,-11.230999946594238 19.452999114990234,2.73799991607666 19.452999114990234,2.73799991607666 C20.343000411987305,3.252000093460083 20.23200035095215,4.150000095367432 19.20400047302246,4.74399995803833 C19.20400047302246,4.74399995803833 8.215999603271484,11.086999893188477 8.215999603271484,11.086999893188477 C7.188000202178955,11.680999755859375 5.632999897003174,11.744999885559082 4.743000030517578,11.230999946594238z"
                        ></path>
                      </g>
                      <g
                        opacity="1"
                        transform="matrix(1,0,0,1,666.8109741210938,659.8829956054688)"
                      >
                        <path
                          fill="rgb(0,204,153)"
                          fill-opacity="1"
                          d=" M2.8559999465942383,10.142000198364258 C2.8559999465942383,10.142000198364258 -17.56599998474121,-1.6490000486373901 -17.56599998474121,-1.6490000486373901 C-18.45599937438965,-2.1630001068115234 -18.344999313354492,-3.059999942779541 -17.316999435424805,-3.6540000438690186 C-17.316999435424805,-3.6540000438690186 -6.329999923706055,-9.998000144958496 -6.329999923706055,-9.998000144958496 C-5.302000045776367,-10.592000007629395 -3.746000051498413,-10.656000137329102 -2.8559999465942383,-10.142000198364258 C-2.8559999465942383,-10.142000198364258 17.56599998474121,1.6490000486373901 17.56599998474121,1.6490000486373901 C18.45599937438965,2.1630001068115234 18.344999313354492,3.059999942779541 17.316999435424805,3.6540000438690186 C17.316999435424805,3.6540000438690186 6.328999996185303,9.998000144958496 6.328999996185303,9.998000144958496 C5.301000118255615,10.592000007629395 3.746000051498413,10.656000137329102 2.8559999465942383,10.142000198364258z"
                        ></path>
                      </g>
                      <g
                        opacity="1"
                        transform="matrix(1,0,0,1,500.6820068359375,583.2169799804688)"
                      >
                        <path
                          fill="rgb(0,204,153)"
                          fill-opacity="1"
                          d=" M-1.8109999895095825,7.447999954223633 C-1.8109999895095825,7.447999954223633 -12.899999618530273,1.0449999570846558 -12.899999618530273,1.0449999570846558 C-13.789999961853027,0.531000018119812 -13.678999900817871,-0.3659999966621399 -12.651000022888184,-0.9599999785423279 C-12.651000022888184,-0.9599999785423279 -1.6629999876022339,-7.303999900817871 -1.6629999876022339,-7.303999900817871 C-0.6349999904632568,-7.8979997634887695 0.9210000038146973,-7.961999893188477 1.8109999895095825,-7.447999954223633 C1.8109999895095825,-7.447999954223633 12.899999618530273,-1.0460000038146973 12.899999618530273,-1.0460000038146973 C13.789999961853027,-0.5320000052452087 13.678000450134277,0.3659999966621399 12.649999618530273,0.9599999785423279 C12.649999618530273,0.9599999785423279 1.6629999876022339,7.303999900817871 1.6629999876022339,7.303999900817871 C0.6349999904632568,7.8979997634887695 -0.9210000038146973,7.961999893188477 -1.8109999895095825,7.447999954223633z"
                        ></path>
                      </g>
                      <g
                        opacity="1"
                        transform="matrix(1,0,0,1,483.1310119628906,573.083984375)"
                      >
                        <path
                          fill="rgb(0,204,153)"
                          fill-opacity="1"
                          d=" M-1.8109999895095825,7.447999954223633 C-1.8109999895095825,7.447999954223633 -12.899999618530273,1.0449999570846558 -12.899999618530273,1.0449999570846558 C-13.789999961853027,0.531000018119812 -13.678999900817871,-0.3659999966621399 -12.651000022888184,-0.9599999785423279 C-12.651000022888184,-0.9599999785423279 -1.6629999876022339,-7.303999900817871 -1.6629999876022339,-7.303999900817871 C-0.6349999904632568,-7.8979997634887695 0.9210000038146973,-7.961999893188477 1.8109999895095825,-7.447999954223633 C1.8109999895095825,-7.447999954223633 12.899999618530273,-1.0460000038146973 12.899999618530273,-1.0460000038146973 C13.789999961853027,-0.5320000052452087 13.678000450134277,0.3659999966621399 12.649999618530273,0.9599999785423279 C12.649999618530273,0.9599999785423279 1.6629999876022339,7.303999900817871 1.6629999876022339,7.303999900817871 C0.6349999904632568,7.8979997634887695 -0.9210000038146973,7.961999893188477 -1.8109999895095825,7.447999954223633z"
                        ></path>
                      </g>
                      <g
                        opacity="1"
                        transform="matrix(1,0,0,1,465.5790100097656,562.9509887695312)"
                      >
                        <path
                          fill="rgb(0,204,153)"
                          fill-opacity="1"
                          d=" M-1.8109999895095825,7.447999954223633 C-1.8109999895095825,7.447999954223633 -12.899999618530273,1.0449999570846558 -12.899999618530273,1.0449999570846558 C-13.789999961853027,0.531000018119812 -13.678000450134277,-0.3659999966621399 -12.649999618530273,-0.9599999785423279 C-12.649999618530273,-0.9599999785423279 -1.6629999876022339,-7.303999900817871 -1.6629999876022339,-7.303999900817871 C-0.6349999904632568,-7.8979997634887695 0.9200000166893005,-7.961999893188477 1.8109999895095825,-7.447999954223633 C1.8109999895095825,-7.447999954223633 12.899999618530273,-1.0449999570846558 12.899999618530273,-1.0449999570846558 C13.789999961853027,-0.531000018119812 13.678999900817871,0.3659999966621399 12.651000022888184,0.9599999785423279 C12.651000022888184,0.9599999785423279 1.6629999876022339,7.303999900817871 1.6629999876022339,7.303999900817871 C0.6349999904632568,7.8979997634887695 -0.9210000038146973,7.961999893188477 -1.8109999895095825,7.447999954223633z"
                        ></path>
                      </g>
                      <g
                        opacity="1"
                        transform="matrix(1,0,0,1,448.02801513671875,552.8179931640625)"
                      >
                        <path
                          fill="rgb(0,204,153)"
                          fill-opacity="1"
                          d=" M-1.8109999895095825,7.447999954223633 C-1.8109999895095825,7.447999954223633 -12.899999618530273,1.0460000038146973 -12.899999618530273,1.0460000038146973 C-13.789999961853027,0.5320000052452087 -13.678000450134277,-0.3659999966621399 -12.649999618530273,-0.9599999785423279 C-12.649999618530273,-0.9599999785423279 -1.6629999876022339,-7.303999900817871 -1.6629999876022339,-7.303999900817871 C-0.6349999904632568,-7.8979997634887695 0.9210000038146973,-7.961999893188477 1.8109999895095825,-7.447999954223633 C1.8109999895095825,-7.447999954223633 12.899999618530273,-1.0449999570846558 12.899999618530273,-1.0449999570846558 C13.791000366210938,-0.531000018119812 13.678999900817871,0.3659999966621399 12.651000022888184,0.9599999785423279 C12.651000022888184,0.9599999785423279 1.6629999876022339,7.303999900817871 1.6629999876022339,7.303999900817871 C0.6349999904632568,7.8979997634887695 -0.9210000038146973,7.961999893188477 -1.8109999895095825,7.447999954223633z"
                        ></path>
                      </g>
                      <g
                        opacity="1"
                        transform="matrix(1,0,0,1,517.8070068359375,593.10498046875)"
                      >
                        <path
                          fill="rgb(0,204,153)"
                          fill-opacity="1"
                          d=" M-1.8109999895095825,7.447999954223633 C-1.8109999895095825,7.447999954223633 -12.899999618530273,1.0449999570846558 -12.899999618530273,1.0449999570846558 C-13.789999961853027,0.531000018119812 -13.678000450134277,-0.3659999966621399 -12.649999618530273,-0.9599999785423279 C-12.649999618530273,-0.9599999785423279 -1.6629999876022339,-7.303999900817871 -1.6629999876022339,-7.303999900817871 C-0.6349999904632568,-7.8979997634887695 0.9200000166893005,-7.961999893188477 1.8109999895095825,-7.447999954223633 C1.8109999895095825,-7.447999954223633 12.899999618530273,-1.0460000038146973 12.899999618530273,-1.0460000038146973 C13.789999961853027,-0.5320000052452087 13.678999900817871,0.3659999966621399 12.651000022888184,0.9599999785423279 C12.651000022888184,0.9599999785423279 1.6629999876022339,7.303999900817871 1.6629999876022339,7.303999900817871 C0.6349999904632568,7.8979997634887695 -0.9210000038146973,7.961999893188477 -1.8109999895095825,7.447999954223633z"
                        ></path>
                      </g>
                      <g
                        opacity="1"
                        transform="matrix(1,0,0,1,534.9320068359375,602.9920043945312)"
                      >
                        <path
                          fill="rgb(0,204,153)"
                          fill-opacity="1"
                          d=" M-1.8109999895095825,7.447999954223633 C-1.8109999895095825,7.447999954223633 -12.899999618530273,1.0449999570846558 -12.899999618530273,1.0449999570846558 C-13.789999961853027,0.531000018119812 -13.678999900817871,-0.3659999966621399 -12.651000022888184,-0.9599999785423279 C-12.651000022888184,-0.9599999785423279 -1.6629999876022339,-7.303999900817871 -1.6629999876022339,-7.303999900817871 C-0.6349999904632568,-7.8979997634887695 0.9210000038146973,-7.961999893188477 1.8109999895095825,-7.447999954223633 C1.8109999895095825,-7.447999954223633 12.899999618530273,-1.0460000038146973 12.899999618530273,-1.0460000038146973 C13.789999961853027,-0.5320000052452087 13.678000450134277,0.3659999966621399 12.649999618530273,0.9599999785423279 C12.649999618530273,0.9599999785423279 1.6629999876022339,7.303999900817871 1.6629999876022339,7.303999900817871 C0.6349999904632568,7.8979997634887695 -0.9210000038146973,7.961999893188477 -1.8109999895095825,7.447999954223633z"
                        ></path>
                      </g>
                      <g
                        opacity="1"
                        transform="matrix(1,0,0,1,552.0570068359375,612.8790283203125)"
                      >
                        <path
                          fill="rgb(0,204,153)"
                          fill-opacity="1"
                          d=" M-1.8109999895095825,7.447999954223633 C-1.8109999895095825,7.447999954223633 -12.899999618530273,1.0460000038146973 -12.899999618530273,1.0460000038146973 C-13.789999961853027,0.5320000052452087 -13.678999900817871,-0.3659999966621399 -12.651000022888184,-0.9599999785423279 C-12.651000022888184,-0.9599999785423279 -1.6629999876022339,-7.303999900817871 -1.6629999876022339,-7.303999900817871 C-0.6349999904632568,-7.8979997634887695 0.9210000038146973,-7.961999893188477 1.8109999895095825,-7.447999954223633 C1.8109999895095825,-7.447999954223633 12.899999618530273,-1.0449999570846558 12.899999618530273,-1.0449999570846558 C13.789999961853027,-0.531000018119812 13.678999900817871,0.3659999966621399 12.651000022888184,0.9599999785423279 C12.651000022888184,0.9599999785423279 1.6629999876022339,7.303999900817871 1.6629999876022339,7.303999900817871 C0.6349999904632568,7.8979997634887695 -0.9210000038146973,7.961999893188477 -1.8109999895095825,7.447999954223633z"
                        ></path>
                      </g>
                      <g
                        opacity="1"
                        transform="matrix(1,0,0,1,569.1820068359375,622.7659912109375)"
                      >
                        <path
                          fill="rgb(0,204,153)"
                          fill-opacity="1"
                          d=" M-1.8109999895095825,7.447999954223633 C-1.8109999895095825,7.447999954223633 -12.899999618530273,1.0460000038146973 -12.899999618530273,1.0460000038146973 C-13.789999961853027,0.5320000052452087 -13.678000450134277,-0.3659999966621399 -12.649999618530273,-0.9599999785423279 C-12.649999618530273,-0.9599999785423279 -1.6629999876022339,-7.303999900817871 -1.6629999876022339,-7.303999900817871 C-0.6349999904632568,-7.8979997634887695 0.9210000038146973,-7.961999893188477 1.8109999895095825,-7.447999954223633 C1.8109999895095825,-7.447999954223633 12.899999618530273,-1.0449999570846558 12.899999618530273,-1.0449999570846558 C13.789999961853027,-0.531000018119812 13.678999900817871,0.3659999966621399 12.651000022888184,0.9599999785423279 C12.651000022888184,0.9599999785423279 1.6629999876022339,7.303999900817871 1.6629999876022339,7.303999900817871 C0.6349999904632568,7.8979997634887695 -0.9210000038146973,7.961999893188477 -1.8109999895095825,7.447999954223633z"
                        ></path>
                      </g>
                      <g
                        opacity="1"
                        transform="matrix(1,0,0,1,586.3070068359375,632.6530151367188)"
                      >
                        <path
                          fill="rgb(0,204,153)"
                          fill-opacity="1"
                          d=" M-1.8109999895095825,7.447999954223633 C-1.8109999895095825,7.447999954223633 -12.899999618530273,1.0460000038146973 -12.899999618530273,1.0460000038146973 C-13.791000366210938,0.5320000052452087 -13.678999900817871,-0.3659999966621399 -12.651000022888184,-0.9599999785423279 C-12.651000022888184,-0.9599999785423279 -1.6629999876022339,-7.303999900817871 -1.6629999876022339,-7.303999900817871 C-0.6349999904632568,-7.8979997634887695 0.9210000038146973,-7.961999893188477 1.8109999895095825,-7.447999954223633 C1.8109999895095825,-7.447999954223633 12.899999618530273,-1.0449999570846558 12.899999618530273,-1.0449999570846558 C13.789999961853027,-0.531000018119812 13.678000450134277,0.3659999966621399 12.649999618530273,0.9599999785423279 C12.649999618530273,0.9599999785423279 1.6629999876022339,7.303999900817871 1.6629999876022339,7.303999900817871 C0.6349999904632568,7.8979997634887695 -0.9210000038146973,7.961999893188477 -1.8109999895095825,7.447999954223633z"
                        ></path>
                      </g>
                      <g
                        opacity="1"
                        transform="matrix(1,0,0,1,603.4320068359375,642.5399780273438)"
                      >
                        <path
                          fill="rgb(0,204,153)"
                          fill-opacity="1"
                          d=" M-1.8109999895095825,7.447999954223633 C-1.8109999895095825,7.447999954223633 -12.899999618530273,1.0460000038146973 -12.899999618530273,1.0460000038146973 C-13.789999961853027,0.5320000052452087 -13.678000450134277,-0.3659999966621399 -12.649999618530273,-0.9599999785423279 C-12.649999618530273,-0.9599999785423279 -1.6629999876022339,-7.303999900817871 -1.6629999876022339,-7.303999900817871 C-0.6349999904632568,-7.8979997634887695 0.9200000166893005,-7.961999893188477 1.8109999895095825,-7.447999954223633 C1.8109999895095825,-7.447999954223633 12.899999618530273,-1.0449999570846558 12.899999618530273,-1.0449999570846558 C13.789999961853027,-0.531000018119812 13.678999900817871,0.3659999966621399 12.651000022888184,0.9599999785423279 C12.651000022888184,0.9599999785423279 1.6629999876022339,7.303999900817871 1.6629999876022339,7.303999900817871 C0.6349999904632568,7.8979997634887695 -0.9210000038146973,7.961999893188477 -1.8109999895095825,7.447999954223633z"
                        ></path>
                      </g>
                      <g
                        opacity="1"
                        transform="matrix(1,0,0,1,620.5570068359375,652.427978515625)"
                      >
                        <path
                          fill="rgb(0,204,153)"
                          fill-opacity="1"
                          d=" M-1.8109999895095825,7.447999954223633 C-1.8109999895095825,7.447999954223633 -12.899999618530273,1.0460000038146973 -12.899999618530273,1.0460000038146973 C-13.789999961853027,0.5320000052452087 -13.678999900817871,-0.3659999966621399 -12.651000022888184,-0.9599999785423279 C-12.651000022888184,-0.9599999785423279 -1.6629999876022339,-7.303999900817871 -1.6629999876022339,-7.303999900817871 C-0.6349999904632568,-7.8979997634887695 0.9210000038146973,-7.961999893188477 1.8109999895095825,-7.447999954223633 C1.8109999895095825,-7.447999954223633 12.899999618530273,-1.0449999570846558 12.899999618530273,-1.0449999570846558 C13.789999961853027,-0.531000018119812 13.678000450134277,0.3659999966621399 12.649999618530273,0.9599999785423279 C12.649999618530273,0.9599999785423279 1.6629999876022339,7.303999900817871 1.6629999876022339,7.303999900817871 C0.6349999904632568,7.8979997634887695 -0.9210000038146973,7.961999893188477 -1.8109999895095825,7.447999954223633z"
                        ></path>
                      </g>
                      <g
                        opacity="1"
                        transform="matrix(1,0,0,1,637.6820068359375,662.3150024414062)"
                      >
                        <path
                          fill="rgb(0,204,153)"
                          fill-opacity="1"
                          d=" M-1.809999942779541,7.447999954223633 C-1.809999942779541,7.447999954223633 -12.899999618530273,1.0460000038146973 -12.899999618530273,1.0460000038146973 C-13.789999961853027,0.5320000052452087 -13.678999900817871,-0.3659999966621399 -12.651000022888184,-0.9599999785423279 C-12.651000022888184,-0.9599999785423279 -1.6629999876022339,-7.303999900817871 -1.6629999876022339,-7.303999900817871 C-0.6349999904632568,-7.8979997634887695 0.9210000038146973,-7.961999893188477 1.8109999895095825,-7.447999954223633 C1.8109999895095825,-7.447999954223633 12.899999618530273,-1.0449999570846558 12.899999618530273,-1.0449999570846558 C13.789999961853027,-0.531000018119812 13.678000450134277,0.3659999966621399 12.649999618530273,0.9599999785423279 C12.649999618530273,0.9599999785423279 1.6629999876022339,7.303999900817871 1.6629999876022339,7.303999900817871 C0.6349999904632568,7.8979997634887695 -0.9200000166893005,7.961999893188477 -1.809999942779541,7.447999954223633z"
                        ></path>
                      </g>
                      <g
                        opacity="1"
                        transform="matrix(1,0,0,1,654.8070068359375,672.2020263671875)"
                      >
                        <path
                          fill="rgb(0,204,153)"
                          fill-opacity="1"
                          d=" M-1.8109999895095825,7.447999954223633 C-1.8109999895095825,7.447999954223633 -12.899999618530273,1.0460000038146973 -12.899999618530273,1.0460000038146973 C-13.789999961853027,0.5320000052452087 -13.678000450134277,-0.3659999966621399 -12.649999618530273,-0.9599999785423279 C-12.649999618530273,-0.9599999785423279 -1.6629999876022339,-7.303999900817871 -1.6629999876022339,-7.303999900817871 C-0.6349999904632568,-7.8979997634887695 0.9210000038146973,-7.961999893188477 1.8109999895095825,-7.447999954223633 C1.8109999895095825,-7.447999954223633 12.899999618530273,-1.0449999570846558 12.899999618530273,-1.0449999570846558 C13.789999961853027,-0.531000018119812 13.678999900817871,0.3659999966621399 12.651000022888184,0.9599999785423279 C12.651000022888184,0.9599999785423279 1.6629999876022339,7.303999900817871 1.6629999876022339,7.303999900817871 C0.6349999904632568,7.8979997634887695 -0.9210000038146973,7.961999893188477 -1.8109999895095825,7.447999954223633z"
                        ></path>
                      </g>
                      <g
                        opacity="1"
                        transform="matrix(1,0,0,1,491.66400146484375,558.7620239257812)"
                      >
                        <path
                          fill="rgb(0,204,153)"
                          fill-opacity="1"
                          d=" M-1.8109999895095825,7.447999954223633 C-1.8109999895095825,7.447999954223633 -12.899999618530273,1.0449999570846558 -12.899999618530273,1.0449999570846558 C-13.789999961853027,0.531000018119812 -13.678000450134277,-0.3659999966621399 -12.649999618530273,-0.9599999785423279 C-12.649999618530273,-0.9599999785423279 -1.6629999876022339,-7.303999900817871 -1.6629999876022339,-7.303999900817871 C-0.6349999904632568,-7.8979997634887695 0.9200000166893005,-7.961999893188477 1.8109999895095825,-7.447999954223633 C1.8109999895095825,-7.447999954223633 12.899999618530273,-1.0449999570846558 12.899999618530273,-1.0449999570846558 C13.789999961853027,-0.531000018119812 13.678999900817871,0.3659999966621399 12.651000022888184,0.9599999785423279 C12.651000022888184,0.9599999785423279 1.6629999876022339,7.303999900817871 1.6629999876022339,7.303999900817871 C0.6349999904632568,7.8979997634887695 -0.9210000038146973,7.961999893188477 -1.8109999895095825,7.447999954223633z"
                        ></path>
                      </g>
                      <g
                        opacity="1"
                        transform="matrix(1,0,0,1,474.6239929199219,548.9240112304688)"
                      >
                        <path
                          fill="rgb(0,204,153)"
                          fill-opacity="1"
                          d=" M-1.8109999895095825,7.447999954223633 C-1.8109999895095825,7.447999954223633 -12.899999618530273,1.0460000038146973 -12.899999618530273,1.0460000038146973 C-13.789999961853027,0.5320000052452087 -13.678000450134277,-0.3659999966621399 -12.649999618530273,-0.9599999785423279 C-12.649999618530273,-0.9599999785423279 -1.6629999876022339,-7.303999900817871 -1.6629999876022339,-7.303999900817871 C-0.6349999904632568,-7.8979997634887695 0.9210000038146973,-7.961999893188477 1.8109999895095825,-7.447999954223633 C1.8109999895095825,-7.447999954223633 12.899999618530273,-1.0449999570846558 12.899999618530273,-1.0449999570846558 C13.789999961853027,-0.531000018119812 13.678999900817871,0.3659999966621399 12.651000022888184,0.9599999785423279 C12.651000022888184,0.9599999785423279 1.6629999876022339,7.303999900817871 1.6629999876022339,7.303999900817871 C0.6349999904632568,7.8979997634887695 -0.9210000038146973,7.961999893188477 -1.8109999895095825,7.447999954223633z"
                        ></path>
                      </g>
                      <g
                        opacity="1"
                        transform="matrix(1,0,0,1,457.5840148925781,539.0859985351562)"
                      >
                        <path
                          fill="rgb(0,204,153)"
                          fill-opacity="1"
                          d=" M-1.8109999895095825,7.447999954223633 C-1.8109999895095825,7.447999954223633 -12.899999618530273,1.0449999570846558 -12.899999618530273,1.0449999570846558 C-13.789999961853027,0.531000018119812 -13.678999900817871,-0.3659999966621399 -12.651000022888184,-0.9599999785423279 C-12.651000022888184,-0.9599999785423279 -1.6629999876022339,-7.303999900817871 -1.6629999876022339,-7.303999900817871 C-0.6349999904632568,-7.8979997634887695 0.9210000038146973,-7.961999893188477 1.8109999895095825,-7.447999954223633 C1.8109999895095825,-7.447999954223633 12.899999618530273,-1.0460000038146973 12.899999618530273,-1.0460000038146973 C13.789999961853027,-0.5320000052452087 13.678999900817871,0.3659999966621399 12.651000022888184,0.9599999785423279 C12.651000022888184,0.9599999785423279 1.6629999876022339,7.303999900817871 1.6629999876022339,7.303999900817871 C0.6349999904632568,7.8979997634887695 -0.9210000038146973,7.961999893188477 -1.8109999895095825,7.447999954223633z"
                        ></path>
                      </g>
                      <g
                        opacity="1"
                        transform="matrix(1,0,0,1,440.5450134277344,529.2479858398438)"
                      >
                        <path
                          fill="rgb(0,204,153)"
                          fill-opacity="1"
                          d=" M-1.8109999895095825,7.447999954223633 C-1.8109999895095825,7.447999954223633 -12.899999618530273,1.0449999570846558 -12.899999618530273,1.0449999570846558 C-13.789999961853027,0.531000018119812 -13.678999900817871,-0.3659999966621399 -12.651000022888184,-0.9599999785423279 C-12.651000022888184,-0.9599999785423279 -1.6629999876022339,-7.303999900817871 -1.6629999876022339,-7.303999900817871 C-0.6349999904632568,-7.8979997634887695 0.9210000038146973,-7.961999893188477 1.8109999895095825,-7.447999954223633 C1.8109999895095825,-7.447999954223633 12.899999618530273,-1.0460000038146973 12.899999618530273,-1.0460000038146973 C13.789999961853027,-0.5320000052452087 13.678000450134277,0.3659999966621399 12.649999618530273,0.9599999785423279 C12.649999618530273,0.9599999785423279 1.6629999876022339,7.303999900817871 1.6629999876022339,7.303999900817871 C0.6349999904632568,7.8979997634887695 -0.9200000166893005,7.961999893188477 -1.8109999895095825,7.447999954223633z"
                        ></path>
                      </g>
                      <g
                        opacity="1"
                        transform="matrix(1,0,0,1,454.3890075683594,521.2550048828125)"
                      >
                        <path
                          fill="rgb(0,204,153)"
                          fill-opacity="1"
                          d=" M1.8669999837875366,5.323999881744385 C1.8669999837875366,5.323999881744385 -9.222000122070312,-1.0779999494552612 -9.222000122070312,-1.0779999494552612 C-10.112000465393066,-1.5920000076293945 -10.00100040435791,-2.489000082015991 -8.972999572753906,-3.0829999446868896 C-8.972999572753906,-3.0829999446868896 -5.341000080108643,-5.181000232696533 -5.341000080108643,-5.181000232696533 C-4.313000202178955,-5.775000095367432 -2.756999969482422,-5.8379998207092285 -1.8669999837875366,-5.323999881744385 C-1.8669999837875366,-5.323999881744385 9.222000122070312,1.0779999494552612 9.222000122070312,1.0779999494552612 C10.112000465393066,1.5920000076293945 10.00100040435791,2.489000082015991 8.972999572753906,3.0829999446868896 C8.972999572753906,3.0829999446868896 5.341000080108643,5.179999828338623 5.341000080108643,5.179999828338623 C4.313000202178955,5.77400016784668 2.756999969482422,5.8379998207092285 1.8669999837875366,5.323999881744385z"
                        ></path>
                      </g>
                      <g
                        opacity="1"
                        transform="matrix(1,0,0,1,476.6260070800781,534.093994140625)"
                      >
                        <path
                          fill="rgb(0,204,153)"
                          fill-opacity="1"
                          d=" M1.8669999837875366,5.323999881744385 C1.8669999837875366,5.323999881744385 -9.222000122070312,-1.0779999494552612 -9.222000122070312,-1.0779999494552612 C-10.112000465393066,-1.5920000076293945 -10.00100040435791,-2.489000082015991 -8.972999572753906,-3.0829999446868896 C-8.972999572753906,-3.0829999446868896 -5.341000080108643,-5.181000232696533 -5.341000080108643,-5.181000232696533 C-4.313000202178955,-5.775000095367432 -2.756999969482422,-5.8379998207092285 -1.8669999837875366,-5.323999881744385 C-1.8669999837875366,-5.323999881744385 9.222999572753906,1.0779999494552612 9.222999572753906,1.0779999494552612 C10.11299991607666,1.5920000076293945 10.00100040435791,2.489000082015991 8.972999572753906,3.0829999446868896 C8.972999572753906,3.0829999446868896 5.341000080108643,5.179999828338623 5.341000080108643,5.179999828338623 C4.313000202178955,5.77400016784668 2.756999969482422,5.8379998207092285 1.8669999837875366,5.323999881744385z"
                        ></path>
                      </g>
                      <g
                        opacity="1"
                        transform="matrix(1,0,0,1,493.5950012207031,543.8909912109375)"
                      >
                        <path
                          fill="rgb(0,204,153)"
                          fill-opacity="1"
                          d=" M1.8669999837875366,5.323999881744385 C1.8669999837875366,5.323999881744385 -9.222000122070312,-1.0779999494552612 -9.222000122070312,-1.0779999494552612 C-10.112000465393066,-1.5920000076293945 -10.00100040435791,-2.489000082015991 -8.972999572753906,-3.0829999446868896 C-8.972999572753906,-3.0829999446868896 -5.340000152587891,-5.179999828338623 -5.340000152587891,-5.179999828338623 C-4.311999797821045,-5.77400016784668 -2.757999897003174,-5.8379998207092285 -1.8669999837875366,-5.323999881744385 C-1.8669999837875366,-5.323999881744385 9.222000122070312,1.0779999494552612 9.222000122070312,1.0779999494552612 C10.112000465393066,1.5920000076293945 10.00100040435791,2.489000082015991 8.972999572753906,3.0829999446868896 C8.972999572753906,3.0829999446868896 5.340000152587891,5.181000232696533 5.340000152587891,5.181000232696533 C4.311999797821045,5.775000095367432 2.757999897003174,5.8379998207092285 1.8669999837875366,5.323999881744385z"
                        ></path>
                      </g>
                      <g
                        opacity="1"
                        transform="matrix(1,0,0,1,510.5639953613281,553.68798828125)"
                      >
                        <path
                          fill="rgb(0,204,153)"
                          fill-opacity="1"
                          d=" M1.8669999837875366,5.323999881744385 C1.8669999837875366,5.323999881744385 -9.222000122070312,-1.0779999494552612 -9.222000122070312,-1.0779999494552612 C-10.112000465393066,-1.5920000076293945 -10.00100040435791,-2.489000082015991 -8.972999572753906,-3.0829999446868896 C-8.972999572753906,-3.0829999446868896 -5.341000080108643,-5.181000232696533 -5.341000080108643,-5.181000232696533 C-4.313000202178955,-5.775000095367432 -2.756999969482422,-5.8379998207092285 -1.8669999837875366,-5.323999881744385 C-1.8669999837875366,-5.323999881744385 9.222000122070312,1.0779999494552612 9.222000122070312,1.0779999494552612 C10.112000465393066,1.5920000076293945 10.00100040435791,2.489000082015991 8.972999572753906,3.0829999446868896 C8.972999572753906,3.0829999446868896 5.341000080108643,5.179999828338623 5.341000080108643,5.179999828338623 C4.313000202178955,5.77400016784668 2.756999969482422,5.8379998207092285 1.8669999837875366,5.323999881744385z"
                        ></path>
                      </g>
                      <g
                        opacity="1"
                        transform="matrix(1,0,0,1,527.5330200195312,563.4849853515625)"
                      >
                        <path
                          fill="rgb(0,204,153)"
                          fill-opacity="1"
                          d=" M1.8669999837875366,5.323999881744385 C1.8669999837875366,5.323999881744385 -9.222000122070312,-1.0779999494552612 -9.222000122070312,-1.0779999494552612 C-10.112000465393066,-1.5920000076293945 -10.00100040435791,-2.489000082015991 -8.972999572753906,-3.0829999446868896 C-8.972999572753906,-3.0829999446868896 -5.341000080108643,-5.179999828338623 -5.341000080108643,-5.179999828338623 C-4.313000202178955,-5.77400016784668 -2.756999969482422,-5.8379998207092285 -1.8669999837875366,-5.323999881744385 C-1.8669999837875366,-5.323999881744385 9.222000122070312,1.0779999494552612 9.222000122070312,1.0779999494552612 C10.112000465393066,1.5920000076293945 10.00100040435791,2.489000082015991 8.972999572753906,3.0829999446868896 C8.972999572753906,3.0829999446868896 5.341000080108643,5.181000232696533 5.341000080108643,5.181000232696533 C4.313000202178955,5.775000095367432 2.756999969482422,5.8379998207092285 1.8669999837875366,5.323999881744385z"
                        ></path>
                      </g>
                      <g
                        opacity="1"
                        transform="matrix(1,0,0,1,622.2670288085938,618.1799926757812)"
                      >
                        <path
                          fill="rgb(0,204,153)"
                          fill-opacity="1"
                          d=" M1.8669999837875366,5.323999881744385 C1.8669999837875366,5.323999881744385 -9.222000122070312,-1.0779999494552612 -9.222000122070312,-1.0779999494552612 C-10.112000465393066,-1.5920000076293945 -10.00100040435791,-2.489000082015991 -8.972999572753906,-3.0829999446868896 C-8.972999572753906,-3.0829999446868896 -5.341000080108643,-5.179999828338623 -5.341000080108643,-5.179999828338623 C-4.313000202178955,-5.77400016784668 -2.756999969482422,-5.8379998207092285 -1.8669999837875366,-5.323999881744385 C-1.8669999837875366,-5.323999881744385 9.222000122070312,1.0779999494552612 9.222000122070312,1.0779999494552612 C10.112000465393066,1.5920000076293945 10.00100040435791,2.489000082015991 8.972999572753906,3.0829999446868896 C8.972999572753906,3.0829999446868896 5.341000080108643,5.181000232696533 5.341000080108643,5.181000232696533 C4.313000202178955,5.775000095367432 2.756999969482422,5.8379998207092285 1.8669999837875366,5.323999881744385z"
                        ></path>
                      </g>
                      <g
                        opacity="1"
                        transform="matrix(1,0,0,1,639.2360229492188,627.9769897460938)"
                      >
                        <path
                          fill="rgb(0,204,153)"
                          fill-opacity="1"
                          d=" M1.8669999837875366,5.323999881744385 C1.8669999837875366,5.323999881744385 -9.222000122070312,-1.0779999494552612 -9.222000122070312,-1.0779999494552612 C-10.112000465393066,-1.5920000076293945 -10.00100040435791,-2.489000082015991 -8.972999572753906,-3.0829999446868896 C-8.972999572753906,-3.0829999446868896 -5.341000080108643,-5.181000232696533 -5.341000080108643,-5.181000232696533 C-4.313000202178955,-5.775000095367432 -2.756999969482422,-5.8379998207092285 -1.8669999837875366,-5.323999881744385 C-1.8669999837875366,-5.323999881744385 9.222000122070312,1.0779999494552612 9.222000122070312,1.0779999494552612 C10.11299991607666,1.5920000076293945 10.00100040435791,2.489000082015991 8.972999572753906,3.0829999446868896 C8.972999572753906,3.0829999446868896 5.341000080108643,5.179999828338623 5.341000080108643,5.179999828338623 C4.313000202178955,5.77400016784668 2.756999969482422,5.8379998207092285 1.8669999837875366,5.323999881744385z"
                        ></path>
                      </g>
                      <g
                        opacity="1"
                        transform="matrix(1,0,0,1,656.2050170898438,637.7739868164062)"
                      >
                        <path
                          fill="rgb(0,204,153)"
                          fill-opacity="1"
                          d=" M1.8669999837875366,5.324999809265137 C1.8669999837875366,5.324999809265137 -9.222000122070312,-1.0779999494552612 -9.222000122070312,-1.0779999494552612 C-10.112000465393066,-1.5920000076293945 -10.00100040435791,-2.489000082015991 -8.972999572753906,-3.0829999446868896 C-8.972999572753906,-3.0829999446868896 -5.340000152587891,-5.179999828338623 -5.340000152587891,-5.179999828338623 C-4.311999797821045,-5.77400016784668 -2.756999969482422,-5.839000225067139 -1.8669999837875366,-5.324999809265137 C-1.8669999837875366,-5.324999809265137 9.222000122070312,1.0779999494552612 9.222000122070312,1.0779999494552612 C10.112000465393066,1.5920000076293945 10.00100040435791,2.490000009536743 8.972999572753906,3.0840001106262207 C8.972999572753906,3.0840001106262207 5.340000152587891,5.181000232696533 5.340000152587891,5.181000232696533 C4.311999797821045,5.775000095367432 2.757999897003174,5.839000225067139 1.8669999837875366,5.324999809265137z"
                        ></path>
                      </g>
                      <g
                        opacity="1"
                        transform="matrix(1,0,0,1,673.1740112304688,647.5700073242188)"
                      >
                        <path
                          fill="rgb(0,204,153)"
                          fill-opacity="1"
                          d=" M1.8669999837875366,5.323999881744385 C1.8669999837875366,5.323999881744385 -9.222000122070312,-1.0779999494552612 -9.222000122070312,-1.0779999494552612 C-10.112000465393066,-1.5920000076293945 -10.00100040435791,-2.489000082015991 -8.972999572753906,-3.0829999446868896 C-8.972999572753906,-3.0829999446868896 -5.341000080108643,-5.181000232696533 -5.341000080108643,-5.181000232696533 C-4.313000202178955,-5.775000095367432 -2.756999969482422,-5.8379998207092285 -1.8669999837875366,-5.323999881744385 C-1.8669999837875366,-5.323999881744385 9.222000122070312,1.0779999494552612 9.222000122070312,1.0779999494552612 C10.112000465393066,1.5920000076293945 10.00100040435791,2.489000082015991 8.972999572753906,3.0829999446868896 C8.972999572753906,3.0829999446868896 5.341000080108643,5.179999828338623 5.341000080108643,5.179999828338623 C4.313000202178955,5.77400016784668 2.756999969482422,5.8379998207092285 1.8669999837875366,5.323999881744385z"
                        ></path>
                      </g>
                      <g
                        opacity="1"
                        transform="matrix(1,0,0,1,508.78900146484375,568.6489868164062)"
                      >
                        <path
                          fill="rgb(0,204,153)"
                          fill-opacity="1"
                          d=" M-1.8109999895095825,7.447999954223633 C-1.8109999895095825,7.447999954223633 -12.899999618530273,1.0449999570846558 -12.899999618530273,1.0449999570846558 C-13.789999961853027,0.531000018119812 -13.678999900817871,-0.3659999966621399 -12.651000022888184,-0.9599999785423279 C-12.651000022888184,-0.9599999785423279 -1.6629999876022339,-7.303999900817871 -1.6629999876022339,-7.303999900817871 C-0.6349999904632568,-7.8979997634887695 0.9210000038146973,-7.961999893188477 1.8109999895095825,-7.447999954223633 C1.8109999895095825,-7.447999954223633 12.899999618530273,-1.0449999570846558 12.899999618530273,-1.0449999570846558 C13.789999961853027,-0.531000018119812 13.678000450134277,0.3659999966621399 12.649999618530273,0.9599999785423279 C12.649999618530273,0.9599999785423279 1.6629999876022339,7.303999900817871 1.6629999876022339,7.303999900817871 C0.6349999904632568,7.8979997634887695 -0.9210000038146973,7.961999893188477 -1.8109999895095825,7.447999954223633z"
                        ></path>
                      </g>
                      <g
                        opacity="1"
                        transform="matrix(1,0,0,1,525.9140014648438,578.5360107421875)"
                      >
                        <path
                          fill="rgb(0,204,153)"
                          fill-opacity="1"
                          d=" M-1.8109999895095825,7.447999954223633 C-1.8109999895095825,7.447999954223633 -12.899999618530273,1.0449999570846558 -12.899999618530273,1.0449999570846558 C-13.789999961853027,0.531000018119812 -13.678999900817871,-0.3659999966621399 -12.651000022888184,-0.9599999785423279 C-12.651000022888184,-0.9599999785423279 -1.6629999876022339,-7.303999900817871 -1.6629999876022339,-7.303999900817871 C-0.6349999904632568,-7.8979997634887695 0.9210000038146973,-7.961999893188477 1.8109999895095825,-7.447999954223633 C1.8109999895095825,-7.447999954223633 12.899999618530273,-1.0449999570846558 12.899999618530273,-1.0449999570846558 C13.789999961853027,-0.531000018119812 13.678999900817871,0.3659999966621399 12.651000022888184,0.9599999785423279 C12.651000022888184,0.9599999785423279 1.6629999876022339,7.303999900817871 1.6629999876022339,7.303999900817871 C0.6349999904632568,7.8979997634887695 -0.9210000038146973,7.961999893188477 -1.8109999895095825,7.447999954223633z"
                        ></path>
                      </g>
                      <g
                        opacity="1"
                        transform="matrix(1,0,0,1,543.0390014648438,588.4240112304688)"
                      >
                        <path
                          fill="rgb(0,204,153)"
                          fill-opacity="1"
                          d=" M-1.8109999895095825,7.447999954223633 C-1.8109999895095825,7.447999954223633 -12.899999618530273,1.0449999570846558 -12.899999618530273,1.0449999570846558 C-13.789999961853027,0.531000018119812 -13.678000450134277,-0.3659999966621399 -12.649999618530273,-0.9599999785423279 C-12.649999618530273,-0.9599999785423279 -1.6629999876022339,-7.303999900817871 -1.6629999876022339,-7.303999900817871 C-0.6349999904632568,-7.8979997634887695 0.9210000038146973,-7.961999893188477 1.8109999895095825,-7.447999954223633 C1.8109999895095825,-7.447999954223633 12.899999618530273,-1.0449999570846558 12.899999618530273,-1.0449999570846558 C13.789999961853027,-0.531000018119812 13.678999900817871,0.3659999966621399 12.651000022888184,0.9599999785423279 C12.651000022888184,0.9599999785423279 1.6629999876022339,7.303999900817871 1.6629999876022339,7.303999900817871 C0.6349999904632568,7.8979997634887695 -0.9210000038146973,7.961999893188477 -1.8109999895095825,7.447999954223633z"
                        ></path>
                      </g>
                      <g
                        opacity="1"
                        transform="matrix(1,0,0,1,560.1649780273438,598.3109741210938)"
                      >
                        <path
                          fill="rgb(0,204,153)"
                          fill-opacity="1"
                          d=" M-1.8109999895095825,7.447999954223633 C-1.8109999895095825,7.447999954223633 -12.899999618530273,1.0449999570846558 -12.899999618530273,1.0449999570846558 C-13.789999961853027,0.531000018119812 -13.678999900817871,-0.3659999966621399 -12.651000022888184,-0.9599999785423279 C-12.651000022888184,-0.9599999785423279 -1.6629999876022339,-7.303999900817871 -1.6629999876022339,-7.303999900817871 C-0.6349999904632568,-7.8979997634887695 0.9210000038146973,-7.961999893188477 1.8109999895095825,-7.447999954223633 C1.8109999895095825,-7.447999954223633 12.899999618530273,-1.0449999570846558 12.899999618530273,-1.0449999570846558 C13.789999961853027,-0.531000018119812 13.678000450134277,0.3659999966621399 12.649999618530273,0.9599999785423279 C12.649999618530273,0.9599999785423279 1.6629999876022339,7.303999900817871 1.6629999876022339,7.303999900817871 C0.6349999904632568,7.8979997634887695 -0.9210000038146973,7.961999893188477 -1.8109999895095825,7.447999954223633z"
                        ></path>
                      </g>
                      <g
                        opacity="1"
                        transform="matrix(1,0,0,1,577.2899780273438,608.197998046875)"
                      >
                        <path
                          fill="rgb(0,204,153)"
                          fill-opacity="1"
                          d=" M-1.8109999895095825,7.447999954223633 C-1.8109999895095825,7.447999954223633 -12.899999618530273,1.0449999570846558 -12.899999618530273,1.0449999570846558 C-13.789999961853027,0.531000018119812 -13.678000450134277,-0.3659999966621399 -12.649999618530273,-0.9599999785423279 C-12.649999618530273,-0.9599999785423279 -1.6629999876022339,-7.303999900817871 -1.6629999876022339,-7.303999900817871 C-0.6349999904632568,-7.8979997634887695 0.9200000166893005,-7.961999893188477 1.8109999895095825,-7.447999954223633 C1.8109999895095825,-7.447999954223633 12.899999618530273,-1.0460000038146973 12.899999618530273,-1.0460000038146973 C13.789999961853027,-0.5320000052452087 13.678999900817871,0.3659999966621399 12.651000022888184,0.9599999785423279 C12.651000022888184,0.9599999785423279 1.6629999876022339,7.303999900817871 1.6629999876022339,7.303999900817871 C0.6349999904632568,7.8979997634887695 -0.9210000038146973,7.961999893188477 -1.8109999895095825,7.447999954223633z"
                        ></path>
                      </g>
                      <g
                        opacity="1"
                        transform="matrix(1,0,0,1,594.4149780273438,618.0850219726562)"
                      >
                        <path
                          fill="rgb(0,204,153)"
                          fill-opacity="1"
                          d=" M-1.8109999895095825,7.447999954223633 C-1.8109999895095825,7.447999954223633 -12.899999618530273,1.0449999570846558 -12.899999618530273,1.0449999570846558 C-13.789999961853027,0.531000018119812 -13.678999900817871,-0.3659999966621399 -12.651000022888184,-0.9599999785423279 C-12.651000022888184,-0.9599999785423279 -1.6629999876022339,-7.303999900817871 -1.6629999876022339,-7.303999900817871 C-0.6349999904632568,-7.8979997634887695 0.9210000038146973,-7.961999893188477 1.8109999895095825,-7.447999954223633 C1.8109999895095825,-7.447999954223633 12.899999618530273,-1.0460000038146973 12.899999618530273,-1.0460000038146973 C13.789999961853027,-0.5320000052452087 13.678000450134277,0.3659999966621399 12.649999618530273,0.9599999785423279 C12.649999618530273,0.9599999785423279 1.6629999876022339,7.303999900817871 1.6629999876022339,7.303999900817871 C0.6349999904632568,7.8979997634887695 -0.9210000038146973,7.961999893188477 -1.8109999895095825,7.447999954223633z"
                        ></path>
                      </g>
                      <g
                        opacity="1"
                        transform="matrix(1,0,0,1,611.5399780273438,627.9719848632812)"
                      >
                        <path
                          fill="rgb(0,204,153)"
                          fill-opacity="1"
                          d=" M-1.8109999895095825,7.447999954223633 C-1.8109999895095825,7.447999954223633 -12.899999618530273,1.0449999570846558 -12.899999618530273,1.0449999570846558 C-13.789999961853027,0.531000018119812 -13.678999900817871,-0.3659999966621399 -12.651000022888184,-0.9599999785423279 C-12.651000022888184,-0.9599999785423279 -1.6629999876022339,-7.303999900817871 -1.6629999876022339,-7.303999900817871 C-0.6349999904632568,-7.8979997634887695 0.9210000038146973,-7.961999893188477 1.8109999895095825,-7.447999954223633 C1.8109999895095825,-7.447999954223633 12.899999618530273,-1.0460000038146973 12.899999618530273,-1.0460000038146973 C13.789999961853027,-0.5320000052452087 13.678999900817871,0.3659999966621399 12.651000022888184,0.9599999785423279 C12.651000022888184,0.9599999785423279 1.6629999876022339,7.303999900817871 1.6629999876022339,7.303999900817871 C0.6349999904632568,7.8979997634887695 -0.9210000038146973,7.961999893188477 -1.8109999895095825,7.447999954223633z"
                        ></path>
                      </g>
                      <g
                        opacity="1"
                        transform="matrix(1,0,0,1,628.6649780273438,637.8590087890625)"
                      >
                        <path
                          fill="rgb(0,204,153)"
                          fill-opacity="1"
                          d=" M-1.8109999895095825,7.447999954223633 C-1.8109999895095825,7.447999954223633 -12.899999618530273,1.0449999570846558 -12.899999618530273,1.0449999570846558 C-13.789999961853027,0.531000018119812 -13.678000450134277,-0.3659999966621399 -12.649999618530273,-0.9599999785423279 C-12.649999618530273,-0.9599999785423279 -1.6629999876022339,-7.303999900817871 -1.6629999876022339,-7.303999900817871 C-0.6349999904632568,-7.8979997634887695 0.9210000038146973,-7.961999893188477 1.8109999895095825,-7.447999954223633 C1.8109999895095825,-7.447999954223633 12.899999618530273,-1.0460000038146973 12.899999618530273,-1.0460000038146973 C13.789999961853027,-0.5320000052452087 13.678999900817871,0.3659999966621399 12.651000022888184,0.9599999785423279 C12.651000022888184,0.9599999785423279 1.6629999876022339,7.303999900817871 1.6629999876022339,7.303999900817871 C0.6349999904632568,7.8979997634887695 -0.9210000038146973,7.961999893188477 -1.8109999895095825,7.447999954223633z"
                        ></path>
                      </g>
                      <g
                        opacity="1"
                        transform="matrix(1,0,0,1,645.7899780273438,647.7470092773438)"
                      >
                        <path
                          fill="rgb(0,204,153)"
                          fill-opacity="1"
                          d=" M-1.8109999895095825,7.447999954223633 C-1.8109999895095825,7.447999954223633 -12.899999618530273,1.0449999570846558 -12.899999618530273,1.0449999570846558 C-13.791000366210938,0.531000018119812 -13.678999900817871,-0.3659999966621399 -12.651000022888184,-0.9599999785423279 C-12.651000022888184,-0.9599999785423279 -1.6629999876022339,-7.303999900817871 -1.6629999876022339,-7.303999900817871 C-0.6349999904632568,-7.8979997634887695 0.9210000038146973,-7.961999893188477 1.8109999895095825,-7.447999954223633 C1.8109999895095825,-7.447999954223633 12.899999618530273,-1.0460000038146973 12.899999618530273,-1.0460000038146973 C13.789999961853027,-0.5320000052452087 13.678000450134277,0.3659999966621399 12.649999618530273,0.9599999785423279 C12.649999618530273,0.9599999785423279 1.6629999876022339,7.303999900817871 1.6629999876022339,7.303999900817871 C0.6349999904632568,7.8979997634887695 -0.9210000038146973,7.961999893188477 -1.8109999895095825,7.447999954223633z"
                        ></path>
                      </g>
                      <g
                        opacity="1"
                        transform="matrix(1,0,0,1,400.0979919433594,563.6430053710938)"
                      >
                        <path
                          fill="rgb(0,204,153)"
                          fill-opacity="1"
                          d=" M8.973999977111816,13.673999786376953 C8.973999977111816,13.673999786376953 -23.684999465942383,-5.181000232696533 -23.684999465942383,-5.181000232696533 C-24.575000762939453,-5.695000171661377 -24.46299934387207,-6.5929999351501465 -23.434999465942383,-7.186999797821045 C-23.434999465942383,-7.186999797821045 -12.447999954223633,-13.529999732971191 -12.447999954223633,-13.529999732971191 C-11.420000076293945,-14.12399959564209 -9.86400032043457,-14.187999725341797 -8.973999977111816,-13.673999786376953 C-8.973999977111816,-13.673999786376953 23.684999465942383,5.181000232696533 23.684999465942383,5.181000232696533 C24.575000762939453,5.695000171661377 24.46299934387207,6.5929999351501465 23.434999465942383,7.186999797821045 C23.434999465942383,7.186999797821045 12.447999954223633,13.531000137329102 12.447999954223633,13.531000137329102 C11.420000076293945,14.125 9.86400032043457,14.187999725341797 8.973999977111816,13.673999786376953z"
                        ></path>
                      </g>
                      <g
                        opacity="1"
                        transform="matrix(1,0,0,1,610.4829711914062,685.1099853515625)"
                      >
                        <path
                          fill="rgb(0,204,153)"
                          fill-opacity="1"
                          d=" M8.973999977111816,13.675000190734863 C8.973999977111816,13.675000190734863 -23.684999465942383,-5.181000232696533 -23.684999465942383,-5.181000232696533 C-24.575000762939453,-5.695000171661377 -24.464000701904297,-6.5929999351501465 -23.43600082397461,-7.186999797821045 C-23.43600082397461,-7.186999797821045 -12.447999954223633,-13.529999732971191 -12.447999954223633,-13.529999732971191 C-11.420000076293945,-14.12399959564209 -9.86400032043457,-14.189000129699707 -8.973999977111816,-13.675000190734863 C-8.973999977111816,-13.675000190734863 23.684999465942383,5.181000232696533 23.684999465942383,5.181000232696533 C24.575000762939453,5.695000171661377 24.464000701904297,6.5929999351501465 23.43600082397461,7.186999797821045 C23.43600082397461,7.186999797821045 12.447999954223633,13.529999732971191 12.447999954223633,13.529999732971191 C11.420000076293945,14.12399959564209 9.86400032043457,14.189000129699707 8.973999977111816,13.675000190734863z"
                        ></path>
                      </g>
                      <g
                        opacity="1"
                        transform="matrix(1,0,0,1,553.8330078125,673.0289916992188)"
                      >
                        <path
                          fill="rgb(0,204,153)"
                          fill-opacity="1"
                          d=" M-2.8610000610351562,8.055000305175781 C-2.8610000610351562,8.055000305175781 -13.951000213623047,1.6519999504089355 -13.951000213623047,1.6519999504089355 C-14.840999603271484,1.1380000114440918 -14.729000091552734,0.23999999463558197 -13.701000213623047,-0.3540000021457672 C-13.701000213623047,-0.3540000021457672 -0.6119999885559082,-7.910999774932861 -0.6119999885559082,-7.910999774932861 C0.41600000858306885,-8.505000114440918 1.9709999561309814,-8.567999839782715 2.8610000610351562,-8.053999900817871 C2.8610000610351562,-8.053999900817871 13.951000213623047,-1.6519999504089355 13.951000213623047,-1.6519999504089355 C14.840999603271484,-1.1380000114440918 14.729000091552734,-0.23999999463558197 13.701000213623047,0.3540000021457672 C13.701000213623047,0.3540000021457672 0.6119999885559082,7.909999847412109 0.6119999885559082,7.909999847412109 C-0.41600000858306885,8.503999710083008 -1.9709999561309814,8.569000244140625 -2.8610000610351562,8.055000305175781z"
                        ></path>
                      </g>
                      <g
                        opacity="1"
                        transform="matrix(1,0,0,1,426.14801025390625,599.3099975585938)"
                      >
                        <path
                          fill="rgb(0,204,153)"
                          fill-opacity="1"
                          d=" M0.460999995470047,9.972999572753906 C0.460999995470047,9.972999572753906 -17.273000717163086,-0.26600000262260437 -17.273000717163086,-0.26600000262260437 C-18.163000106811523,-0.7799999713897705 -18.052000045776367,-1.6779999732971191 -17.02400016784668,-2.2720000743865967 C-17.02400016784668,-2.2720000743865967 -3.934999942779541,-9.829000473022461 -3.934999942779541,-9.829000473022461 C-2.9070000648498535,-10.42300033569336 -1.3509999513626099,-10.487000465393066 -0.460999995470047,-9.972999572753906 C-0.460999995470047,-9.972999572753906 17.273000717163086,0.26600000262260437 17.273000717163086,0.26600000262260437 C18.163000106811523,0.7799999713897705 18.052000045776367,1.6779999732971191 17.02400016784668,2.2720000743865967 C17.02400016784668,2.2720000743865967 3.934999942779541,9.829000473022461 3.934999942779541,9.829000473022461 C2.9070000648498535,10.42300033569336 1.3509999513626099,10.487000465393066 0.460999995470047,9.972999572753906z"
                        ></path>
                      </g>
                      <g
                        opacity="1"
                        transform="matrix(1,0,0,1,534.010009765625,661.583984375)"
                      >
                        <path
                          fill="rgb(0,204,153)"
                          fill-opacity="1"
                          d=" M0.460999995470047,9.972999572753906 C0.460999995470047,9.972999572753906 -17.273000717163086,-0.26600000262260437 -17.273000717163086,-0.26600000262260437 C-18.163999557495117,-0.7799999713897705 -18.052000045776367,-1.6779999732971191 -17.02400016784668,-2.2720000743865967 C-17.02400016784668,-2.2720000743865967 -3.934999942779541,-9.829000473022461 -3.934999942779541,-9.829000473022461 C-2.9070000648498535,-10.42300033569336 -1.3509999513626099,-10.487000465393066 -0.460999995470047,-9.972999572753906 C-0.460999995470047,-9.972999572753906 17.273000717163086,0.26600000262260437 17.273000717163086,0.26600000262260437 C18.163999557495117,0.7799999713897705 18.052000045776367,1.6779999732971191 17.02400016784668,2.2720000743865967 C17.02400016784668,2.2720000743865967 3.934999942779541,9.829000473022461 3.934999942779541,9.829000473022461 C2.9070000648498535,10.42300033569336 1.3509999513626099,10.487000465393066 0.460999995470047,9.972999572753906z"
                        ></path>
                      </g>
                      <g
                        opacity="1"
                        transform="matrix(1,0,0,1,450.8559875488281,613.5750122070312)"
                      >
                        <path
                          fill="rgb(0,204,153)"
                          fill-opacity="1"
                          d=" M0.460999995470047,9.972999572753906 C0.460999995470047,9.972999572753906 -17.273000717163086,-0.26600000262260437 -17.273000717163086,-0.26600000262260437 C-18.163000106811523,-0.7799999713897705 -18.052000045776367,-1.6779999732971191 -17.02400016784668,-2.2720000743865967 C-17.02400016784668,-2.2720000743865967 -3.934999942779541,-9.829000473022461 -3.934999942779541,-9.829000473022461 C-2.9070000648498535,-10.42300033569336 -1.3509999513626099,-10.487000465393066 -0.460999995470047,-9.972999572753906 C-0.460999995470047,-9.972999572753906 17.273000717163086,0.26600000262260437 17.273000717163086,0.26600000262260437 C18.163000106811523,0.7799999713897705 18.052000045776367,1.6779999732971191 17.02400016784668,2.2720000743865967 C17.02400016784668,2.2720000743865967 3.934999942779541,9.829000473022461 3.934999942779541,9.829000473022461 C2.9070000648498535,10.42300033569336 1.3509999513626099,10.487000465393066 0.460999995470047,9.972999572753906z"
                        ></path>
                      </g>
                      <g
                        opacity="1"
                        transform="matrix(1,0,0,1,481.0199890136719,630.989990234375)"
                      >
                        <path
                          fill="rgb(0,204,153)"
                          fill-opacity="1"
                          d=" M-30.5049991607666,-27.319000244140625 C-30.5049991607666,-27.319000244140625 46.599998474121094,17.197999954223633 46.599998474121094,17.197999954223633 C47.55699920654297,17.75 47.55699920654297,18.645999908447266 46.599998474121094,19.197999954223633 C46.599998474121094,19.197999954223633 33.25199890136719,26.905000686645508 33.25199890136719,26.905000686645508 C32.29499816894531,27.457000732421875 30.7450008392334,27.457000732421875 29.788000106811523,26.905000686645508 C29.788000106811523,26.905000686645508 -47.31800079345703,-17.613000869750977 -47.31800079345703,-17.613000869750977 C-47.31800079345703,-17.613000869750977 -30.5049991607666,-27.319000244140625 -30.5049991607666,-27.319000244140625z"
                        ></path>
                      </g>
                      <g
                        opacity="1"
                        transform="matrix(1,0,0,1,600.8070068359375,705.0029907226562)"
                      >
                        <path
                          fill="rgb(0,204,153)"
                          fill-opacity="1"
                          d=" M-1.9730000495910645,-5.992000102996826 C-1.9730000495910645,-5.992000102996826 10.378999710083008,1.1390000581741333 10.378999710083008,1.1390000581741333 C11.109000205993652,1.559999942779541 11.017999649047852,2.296999931335449 10.175000190734863,2.7829999923706055 C10.175000190734863,2.7829999923706055 4.821000099182129,5.874000072479248 4.821000099182129,5.874000072479248 C3.9779999256134033,6.360000133514404 2.703000068664551,6.413000106811523 1.9730000495910645,5.992000102996826 C1.9730000495910645,5.992000102996826 -10.378999710083008,-1.1390000581741333 -10.378999710083008,-1.1390000581741333 C-11.109000205993652,-1.559999942779541 -11.017999649047852,-2.2960000038146973 -10.175000190734863,-2.7829999923706055 C-10.175000190734863,-2.7829999923706055 -4.820000171661377,-5.874000072479248 -4.820000171661377,-5.874000072479248 C-3.9769999980926514,-6.360000133514404 -2.703000068664551,-6.413000106811523 -1.9730000495910645,-5.992000102996826z"
                        ></path>
                      </g>
                      <g
                        opacity="1"
                        transform="matrix(1,0,0,1,583.5399780273438,695.0330200195312)"
                      >
                        <path
                          fill="rgb(0,204,153)"
                          fill-opacity="1"
                          d=" M-1.9730000495910645,-5.992000102996826 C-1.9730000495910645,-5.992000102996826 10.378999710083008,1.1390000581741333 10.378999710083008,1.1390000581741333 C11.109000205993652,1.559999942779541 11.017999649047852,2.2960000038146973 10.175000190734863,2.7829999923706055 C10.175000190734863,2.7829999923706055 4.820000171661377,5.874000072479248 4.820000171661377,5.874000072479248 C3.9769999980926514,6.360000133514404 2.703000068664551,6.413000106811523 1.9730000495910645,5.992000102996826 C1.9730000495910645,5.992000102996826 -10.378999710083008,-1.1390000581741333 -10.378999710083008,-1.1390000581741333 C-11.109000205993652,-1.559999942779541 -11.017999649047852,-2.296999931335449 -10.175000190734863,-2.7829999923706055 C-10.175000190734863,-2.7829999923706055 -4.820000171661377,-5.874000072479248 -4.820000171661377,-5.874000072479248 C-3.9769999980926514,-6.360000133514404 -2.703000068664551,-6.413000106811523 -1.9730000495910645,-5.992000102996826z"
                        ></path>
                      </g>
                      <g
                        opacity="1"
                        transform="matrix(1,0,0,1,566.4149780273438,685.14599609375)"
                      >
                        <path
                          fill="rgb(0,204,153)"
                          fill-opacity="1"
                          d=" M-1.9730000495910645,-5.992000102996826 C-1.9730000495910645,-5.992000102996826 10.378999710083008,1.1390000581741333 10.378999710083008,1.1390000581741333 C11.107999801635742,1.559999942779541 11.017999649047852,2.296999931335449 10.175000190734863,2.7829999923706055 C10.175000190734863,2.7829999923706055 4.820000171661377,5.874000072479248 4.820000171661377,5.874000072479248 C3.9769999980926514,6.360000133514404 2.703000068664551,6.413000106811523 1.9730000495910645,5.992000102996826 C1.9730000495910645,5.992000102996826 -10.378999710083008,-1.1390000581741333 -10.378999710083008,-1.1390000581741333 C-11.109000205993652,-1.559999942779541 -11.017999649047852,-2.296999931335449 -10.175000190734863,-2.7829999923706055 C-10.175000190734863,-2.7829999923706055 -4.821000099182129,-5.874000072479248 -4.821000099182129,-5.874000072479248 C-3.9779999256134033,-6.360000133514404 -2.703000068664551,-6.413000106811523 -1.9730000495910645,-5.992000102996826z"
                        ></path>
                      </g>
                      <g
                        opacity="1"
                        transform="matrix(1,0,0,1,592.9970092773438,689.572998046875)"
                      >
                        <path
                          fill="rgb(0,204,153)"
                          fill-opacity="1"
                          d=" M-1.9730000495910645,-5.992000102996826 C-1.9730000495910645,-5.992000102996826 10.378999710083008,1.1390000581741333 10.378999710083008,1.1390000581741333 C11.109000205993652,1.559999942779541 11.017999649047852,2.296999931335449 10.175000190734863,2.7829999923706055 C10.175000190734863,2.7829999923706055 4.820000171661377,5.874000072479248 4.820000171661377,5.874000072479248 C3.9769999980926514,6.360000133514404 2.703000068664551,6.413000106811523 1.9730000495910645,5.992000102996826 C1.9730000495910645,5.992000102996826 -10.378999710083008,-1.1390000581741333 -10.378999710083008,-1.1390000581741333 C-11.109000205993652,-1.559999942779541 -11.017999649047852,-2.296999931335449 -10.175000190734863,-2.7829999923706055 C-10.175000190734863,-2.7829999923706055 -4.820000171661377,-5.874000072479248 -4.820000171661377,-5.874000072479248 C-3.9769999980926514,-6.360000133514404 -2.703000068664551,-6.413000106811523 -1.9730000495910645,-5.992000102996826z"
                        ></path>
                      </g>
                    </g>
                    <g opacity="1" transform="matrix(1,0,0,1,0,0)">
                      <g
                        opacity="1"
                        transform="matrix(1,0,0,1,543.2689819335938,374.0400085449219)"
                      >
                        <path
                          fill="rgb(242,228,205)"
                          fill-opacity="1"
                          d=" M13.333000183105469,11.038999557495117 C13.333000183105469,11.038999557495117 -14.520000457763672,-5.041999816894531 -14.520000457763672,-5.041999816894531 C-14.520000457763672,-5.041999816894531 -13.333000183105469,-11.038999557495117 -13.333000183105469,-11.038999557495117 C-13.333000183105469,-11.038999557495117 14.520000457763672,5.041999816894531 14.520000457763672,5.041999816894531 C14.520000457763672,5.041999816894531 13.333000183105469,11.038999557495117 13.333000183105469,11.038999557495117z"
                        ></path>
                      </g>
                      <g
                        opacity="1"
                        transform="matrix(1,0,0,1,540.2459716796875,387.97698974609375)"
                      >
                        <path
                          fill="rgb(54,109,89)"
                          fill-opacity="1"
                          d=" M13.333000183105469,11.038999557495117 C13.333000183105469,11.038999557495117 -14.520000457763672,-5.041999816894531 -14.520000457763672,-5.041999816894531 C-14.520000457763672,-5.041999816894531 -13.333000183105469,-11.038999557495117 -13.333000183105469,-11.038999557495117 C-13.333000183105469,-11.038999557495117 14.520000457763672,5.041999816894531 14.520000457763672,5.041999816894531 C14.520000457763672,5.041999816894531 13.333000183105469,11.038999557495117 13.333000183105469,11.038999557495117z"
                        ></path>
                      </g>
                      <g
                        opacity="1"
                        transform="matrix(1,0,0,1,676.9530029296875,581.280029296875)"
                      >
                        <path
                          fill="rgb(242,228,205)"
                          fill-opacity="1"
                          d=" M13.333000183105469,11.038999557495117 C13.333000183105469,11.038999557495117 -14.520000457763672,-5.041999816894531 -14.520000457763672,-5.041999816894531 C-14.520000457763672,-5.041999816894531 -13.333000183105469,-11.038999557495117 -13.333000183105469,-11.038999557495117 C-13.333000183105469,-11.038999557495117 14.520000457763672,5.041999816894531 14.520000457763672,5.041999816894531 C14.520000457763672,5.041999816894531 13.333000183105469,11.038999557495117 13.333000183105469,11.038999557495117z"
                        ></path>
                      </g>
                    </g>
                  </g>
                  <g
                    transform="matrix(1.4299999475479126,0,0,1.4299999475479126,-178.8074951171875,-263.9505615234375)"
                    opacity="1"
                    style={{ display: "block" }}
                  >
                    <g
                      opacity="1"
                      transform="matrix(1,0,0,1,594.3090209960938,429.322998046875)"
                    >
                      <path
                        fill="rgb(221,84,119)"
                        fill-opacity="1"
                        d=" M9.795000076293945,27.163000106811523 C9.795000076293945,27.163000106811523 -18.281999588012695,10.95300006866455 -18.281999588012695,10.95300006866455 C-20.767000198364258,9.517999649047852 -22.249000549316406,5.9629998207092285 -21.591999053955078,3.013000011444092 C-21.591999053955078,3.013000011444092 -15.484000205993652,-24.41900062561035 -15.484000205993652,-24.41900062561035 C-14.82699966430664,-27.368999481201172 -12.279999732971191,-28.597999572753906 -9.795000076293945,-27.163000106811523 C-9.795000076293945,-27.163000106811523 18.281999588012695,-10.95300006866455 18.281999588012695,-10.95300006866455 C20.767000198364258,-9.517999649047852 22.249000549316406,-5.9629998207092285 21.591999053955078,-3.013000011444092 C21.591999053955078,-3.013000011444092 15.484000205993652,24.41900062561035 15.484000205993652,24.41900062561035 C14.82699966430664,27.368999481201172 12.279999732971191,28.597999572753906 9.795000076293945,27.163000106811523z"
                      ></path>
                    </g>
                    <g
                      opacity="1"
                      transform="matrix(1,0,0,1,643.2839965820312,457.5979919433594)"
                    >
                      <path
                        fill="rgb(244,211,93)"
                        fill-opacity="1"
                        d=" M9.795000076293945,27.163000106811523 C9.795000076293945,27.163000106811523 -18.281999588012695,10.95300006866455 -18.281999588012695,10.95300006866455 C-20.767000198364258,9.517999649047852 -22.249000549316406,5.9629998207092285 -21.591999053955078,3.013000011444092 C-21.591999053955078,3.013000011444092 -15.484000205993652,-24.41900062561035 -15.484000205993652,-24.41900062561035 C-14.82699966430664,-27.368999481201172 -12.279999732971191,-28.597999572753906 -9.795000076293945,-27.163000106811523 C-9.795000076293945,-27.163000106811523 18.281999588012695,-10.95300006866455 18.281999588012695,-10.95300006866455 C20.767000198364258,-9.517999649047852 22.249000549316406,-5.9629998207092285 21.591999053955078,-3.013000011444092 C21.591999053955078,-3.013000011444092 15.484000205993652,24.41900062561035 15.484000205993652,24.41900062561035 C14.82699966430664,27.368999481201172 12.279999732971191,28.597999572753906 9.795000076293945,27.163000106811523z"
                      ></path>
                    </g>
                    <g
                      opacity="1"
                      transform="matrix(1,0,0,1,583.4580078125,478.0559997558594)"
                    >
                      <path
                        fill="rgb(54,109,89)"
                        fill-opacity="1"
                        d=" M9.795000076293945,27.163000106811523 C9.795000076293945,27.163000106811523 -18.281999588012695,10.95300006866455 -18.281999588012695,10.95300006866455 C-20.767000198364258,9.517999649047852 -22.249000549316406,5.9629998207092285 -21.591999053955078,3.013000011444092 C-21.591999053955078,3.013000011444092 -15.484000205993652,-24.41900062561035 -15.484000205993652,-24.41900062561035 C-14.82699966430664,-27.368999481201172 -12.279999732971191,-28.597999572753906 -9.795000076293945,-27.163000106811523 C-9.795000076293945,-27.163000106811523 18.281999588012695,-10.95300006866455 18.281999588012695,-10.95300006866455 C20.767000198364258,-9.517999649047852 22.249000549316406,-5.9629998207092285 21.591999053955078,-3.013000011444092 C21.591999053955078,-3.013000011444092 15.484000205993652,24.41900062561035 15.484000205993652,24.41900062561035 C14.82699966430664,27.368999481201172 12.279999732971191,28.597999572753906 9.795000076293945,27.163000106811523z"
                      ></path>
                    </g>
                    <g
                      opacity="1"
                      transform="matrix(1,0,0,1,632.4329833984375,506.33099365234375)"
                    >
                      <path
                        fill="rgb(0,204,153)"
                        fill-opacity="1"
                        d=" M9.795000076293945,27.163000106811523 C9.795000076293945,27.163000106811523 -18.281999588012695,10.95300006866455 -18.281999588012695,10.95300006866455 C-20.767000198364258,9.517999649047852 -22.249000549316406,5.9629998207092285 -21.591999053955078,3.013000011444092 C-21.591999053955078,3.013000011444092 -15.484000205993652,-24.41900062561035 -15.484000205993652,-24.41900062561035 C-14.82699966430664,-27.368999481201172 -12.279999732971191,-28.597999572753906 -9.795000076293945,-27.163000106811523 C-9.795000076293945,-27.163000106811523 18.281999588012695,-10.95300006866455 18.281999588012695,-10.95300006866455 C20.767000198364258,-9.517999649047852 22.249000549316406,-5.9629998207092285 21.591999053955078,-3.013000011444092 C21.591999053955078,-3.013000011444092 15.484000205993652,24.41900062561035 15.484000205993652,24.41900062561035 C14.82699966430664,27.368999481201172 12.279999732971191,28.597999572753906 9.795000076293945,27.163000106811523z"
                      ></path>
                    </g>
                  </g>
                </g>
              </svg>
            </Box>
          </Grid>
          <Grid size={6}>
            <Box>
              <p>
                In 2024, a typical tenant received <strong>1501</strong>{" "}
                Microsoft Message Center messages: 71% "Stay Informed", 27%
                "Plan for Change, 2% "Prevent or Fix Issues". Managing this and
                ensuring you catch all important changes is quite challenging.
              </p>
              <p>
                ChangePilot allows you to efficiently and confidently manage
                Microsoft 365 changes:
              </p>
              <ul>
                <li>
                  <strong>Expert Reviews: </strong>Every M365 change item is
                  reviewed summarized and rated for impact by experts.
                </li>
                <li>
                  <strong>Monthly Change Call: </strong>Join our Microsoft
                  MVP/MCM lead change call, explaining each change in detail.
                  Access recorded sessions on demand, with convenient chapter
                  marks. marks.
                </li>
                <li>
                  <strong>Customised Email Summaries:</strong>Change email
                  summaries sent to IT admins/service owners, filtered as you
                  see fit by workloads and impact
                </li>
                <li>
                  <strong>ChangePilot Portal:</strong>Interactive Power BI
                  reports of all your tenant's messages with summaries and
                  explainer video links
                </li>
                <li>
                  <strong>Tenant Connection:</strong>Simple 1 click read-only
                  access to tenant message center API to receive updates
                  specifically for your M365 environment.
                </li>
                <li>
                  ChangePilot Pro adds a full best practice management process,
                  all on your tenant leveraging Teams, ChangePilot Power App,
                  Power BI and Power Automate to push items to your ITSM
                  platform.
                </li>
              </ul>
              <p>
                Sign up now for instant access to our standard changes feed and
                60 days free trial.
              </p>
            </Box>
            <Stack direction={"row"} spacing={2}>
              <Button
                href="https://changepilot.cloud"
                target="_blank"
                rel="noopener noreferrer"
                variant={"contained"}
              >
                More info
              </Button>
              <Button
                href="https://changepilot.cloud"
                target="_blank"
                rel="noopener noreferrer"
                variant={"contained"}
              >
                Get started with a 60-day trial
              </Button>
            </Stack>
          </Grid>
        </Grid>
        <Container style={{ marginTop: "10" }}>
          <h3>Latest Microsoft 365 Message Center Items</h3>
          <p>
            Our dedicated team of Microsoft MVPs reviews, summarizes, and
            assigns impact scores to every change from the M365 Message Center.
            Below, you can find the 30 most recent items.
          </p>
          <p>
            Note each Microsoft 365 tenant gets a unique feed of messages based
            on type, licences, configuration, usage and location.
          </p>
          <p>
            To access all M365 Message Center messages and Roadmap items, sign
            in and start a 60 day trial{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://changepilot.cloud"
            >
              here
            </a>
          </p>
          <div
            className="ag-theme-balham"
            style={{
              height: "100%",
              width: "100%",
              minHeight: "100%",
              marginTop: "10px",
              overflow: "hidden",
              position: "relative",
            }}
          >
            <AgGridReact
              columnDefs={columnDefs}
              rowData={items}
              theme={gridTheme}
              rowHeight={rowHeight}
              suppressRowHoverHighlight={true}
              suppressRowDrag={true}
              columnHoverHighlight={false}
              domLayout="autoHeight"
              tooltipShowDelay={0}
            />
          </div>
        </Container>
      </Container>
    </>
  );
};

export default NotLoggedInPage;
