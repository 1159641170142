import React, { useEffect, useState, useRef } from "react";
import callApi from "../services/apiService";

interface Item {
  mcid: string;
  title: string;
  updated: Date;
}

const ListCategoryItems: React.FC<{ category: string }> = ({ category }) => {
  const [items, setItems] = useState<Item[]>();

  const [loading, setLoading] = useState(true);
  const effectRan = useRef(false);

  useEffect(() => {
    if (effectRan.current) {
      return;
    }
    effectRan.current = true;

    const fetchSiteList = async () => {
      try {
        const items: Item[] = await callApi.getMessageCenterItemsByCategory(
          category
        );
        setItems(items);

        setLoading(false);
      } catch (error) {
        console.error("Failed to fetch site list:", error);
      }
    };

    fetchSiteList();
  }, [category]);

  return (
    <>
      {!loading &&
        items?.map((item) => {
          const title = item.mcid + ": " + item.title;
          return (
            <li key={item.mcid}>
              <a href={`${window.location.origin}/${item.mcid}`}>{title}</a>
            </li>
          );
        })}

      {loading && <p>Loading...</p>}

      {!loading && items?.length === 0 && (
        <>
          <h3>
            There aren't any Message Center items for this category at this
            time, or the category is invalid.
          </h3>
        </>
      )}
    </>
  );
};

export default ListCategoryItems;
