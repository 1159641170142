import React from "react";

const NotFound: React.FC = () => {
  return (
    <>
      <div>
        <h2>Page Not Found</h2>
        <h3>
          <a
            href="https://changepilot.cloud/"
            target="_blank"
            rel="noreferrer noopener"
          >
            Read more about ChangePilot
          </a>
        </h3>
      </div>
    </>
  );
};

export default NotFound;
