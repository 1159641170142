import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";

import { AdminConsentModel } from "../Models/AdminConsentModel";

const baseURL = "https://empoweringcloudapi.azurewebsites.net";
//const baseURL = "https://localhost:7172";

export function useSetAdminConsent() {
  const { user, getAccessTokenSilently } = useAuth0();

  const setAdminConsent = async (
    model: AdminConsentModel
  ): Promise<boolean> => {
    try {
      const accessToken = user != null ? await getAccessTokenSilently() : null;

      const response = await axios.post(`${baseURL}/me/AdminConsent`, model, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (response.status === 200) {
        console.log("Returning true");
        return true;
      }

      console.log("Returning false");
      return false;
    } catch (error) {
      // Handle the error here
      console.log("Error calling the getMessageCenterEvents API: ", error);
      console.log("Returning false");
      return false;
    }
  };

  return setAdminConsent;
}
