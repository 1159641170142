import React from "react";
import MessageCenterDetail from "./components/MessageCenterDetail";
import SitemapRouteHandler from "./SitemapRouteHandler";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { blueGrey } from "@mui/material/colors";
import Redirect from "./components/Redirect";
import AllItemsPage from "./components/AllItemsPage";
import DefaultPage from "./components/DefaultPage";
import ProductPage from "./components/ProductPage";
import Footer from "./components/Footer";
import CategoryPage from "./components/CategoryPage";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  useParams,
} from "react-router-dom";
import NotFoundPage from "./components/NotFoundPage";

import "./App.css";
import AdminConsentLanding from "./components/pages/AdminConsentLanding";
import NotLoggedInPage from "./components/pages/NotLoggedInPage";

interface Params extends Record<string, string | undefined> {
  param: string;
}

const theme = createTheme({
  palette: {
    primary: blueGrey,
    secondary: blueGrey,
  },
});

const categoryTypes = [
  "Stay informed",
  "Prevent or fix issues",
  "Plan for change",
];

const MessageCenterRouteHandler: React.FC = () => {
  const { param } = useParams<Params>();

  if (!param) {
    // Handle the case where param is undefined, for example by redirecting to a fallback route
    return <Redirect url={"https://changepilot.cloud"} />;
  }

  if (param.toUpperCase().startsWith("MC")) {
    return <MessageCenterDetail />;
  } else if (
    categoryTypes.some(
      (item: string) =>
        item.toLowerCase().replace(/ /g, "-") ===
        decodeURIComponent(param.toLowerCase()).replace(/ /g, "-")
    )
  ) {
    return <CategoryPage />;
  } else {
    return <ProductPage />;
  }
};

function App() {
  //const ChangePilotHomePage = "https://changepilot.cloud";
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Routes>
          <Route path="/:param" element={<MessageCenterRouteHandler />} />
          <Route path="/sitemapgen" element={<SitemapRouteHandler />} />
          {/* <Route path="/" element={<Redirect url={ChangePilotHomePage} />} /> */}
          <Route path="/" element={<NotLoggedInPage />} />
          <Route path="/beta" element={<DefaultPage />} />
          <Route path="/all" element={<AllItemsPage />} />
          <Route path="/adminconsent" element={<AdminConsentLanding />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </Router>
      <Footer />
    </ThemeProvider>
  );
}

export default App;
