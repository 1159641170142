import React from "react";
import Stack from "@mui/material/Stack";
import Container from "@mui/material/Container";

const Footer: React.FC = () => {
  return (
    <>
      <footer className="footer">
        <Container maxWidth="lg" className="footerContainer">
          <Stack
            direction="row"
            spacing={2}
            justifyContent="center"
            className="footerCollection"
          >
            <a
              href="https://changepilot.cloud/privacy-policy"
              target="_blank"
              rel="noreferrer noopener"
              className="footerLink"
            >
              Privacy Policy
            </a>
            <a
              href="https://changepilot.cloud/terms-of-service"
              target="_blank"
              rel="noreferrer noopener"
              className="footerLink"
            >
              Terms of Service
            </a>
            <a
              href="https://changepilot.cloud/acceptable-use-policy"
              target="_blank"
              rel="noreferrer noopener"
              className="footerLink"
            >
              Acceptable Use Policy
            </a>
            <a
              href="https://changepilot.cloud/cookie-policy"
              target="_blank"
              rel="noreferrer noopener"
              className="footerLink"
            >
              Cookie Policy
            </a>
            <a
              href="https://changepilot.cloud/knowledge-base"
              target="_blank"
              rel="noreferrer noopener"
              className="footerLink"
            >
              ChangePilot Knowledge Base
            </a>
          </Stack>
        </Container>
        <Container maxWidth="xl">
          <div className="footerCopyright">
            <p className="footerText">
              &copy; Copyright 2025{" "}
              <a
                href="https://changepilot.cloud"
                target="_blank"
                rel="noreferrer noopener"
              >
                Empowering.Cloud Ltd. All rights reserved.
              </a>
            </p>
          </div>
          &nbsp;
        </Container>
      </footer>
    </>
  );
};

export default Footer;
