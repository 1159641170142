import React from "react";
import TopMenuBar from "./TopMenuBar";
import ListCategoryItems from "./ListCategoryItems";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

interface Params extends Record<string, string> {
  param: string;
}

function getUrl(): string {
  if (typeof window == "undefined") return "";
  return window.location.href;
}

const CategoryPage: React.FC = () => {
  let param = useParams<Params>().param?.toString() || "none";
  let metaTitle = `${titleCase(param).replace(/-/g, " ")}} - ChangePilot `;
  let metaDescription = `All Microsoft 365 Message Center messages categorised: ${titleCase(
    param
  ).replace(/-/g, " ")}} `;
  const fallBackLogo =
    window.location.protocol +
    "//" +
    window.location.hostname +
    "/ChangePilotFaviconMint500px.png";
  const jsonLd = {
    "@context": "https://schema.org",
    "@type": "WebSite",
    name: metaTitle,
    alternateName: "ChangePilot from Empowering Cloud",
    url: getUrl(),
    image: fallBackLogo,
  };

  function titleCase(str: string): string {
    return str
      .toLowerCase()
      .split(" ")
      .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  }

  return (
    <>
      <TopMenuBar />
      <div>
        <Container>
          <Helmet>
            <title>{metaTitle}</title>
            {metaDescription && (
              <meta name="description" content={metaDescription} />
            )}
            {metaTitle && <meta property="og:title" content={metaTitle} />}
            {metaDescription && (
              <meta property="og:description" content={metaDescription} />
            )}
            <meta property="og:type" content="website"></meta>
            <script type="application/ld+json">{JSON.stringify(jsonLd)}</script>
            {getUrl() && <meta property="og:url" content={getUrl()} />}
            <meta property="og:image" content={fallBackLogo} />
            <link rel="canonical" href={getUrl()} />
          </Helmet>
          <Box component="section" sx={{ p: 2 }}>
            <h1>
              Microsoft 365 Message Center:{" "}
              {titleCase(param).replace(/-/g, " ")} Messages
            </h1>
            <ListCategoryItems category={param} />
          </Box>
        </Container>
      </div>
    </>
  );
};

export default CategoryPage;
